import { DatePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DiagramModel } from '../models/diagram.model';

@Component({
  selector: 'app-last-edited',
  standalone: true,
  imports: [TranslateModule, DatePipe],
  templateUrl: './last-edited.component.html',
  styleUrl: './last-edited.component.scss'
})
export class LastEditedComponent {
  diagram = input<DiagramModel>();
}
