<div class="flex-row-start-center gap-10">
  <a class="back-button" (click)="backToDiagrams()">
    <fa-icon [icon]="faArrowLeft"></fa-icon>
    {{ "workgroups.detail.backButton" | translate }}
  </a>
  <h1>{{ diagram()?.name }}</h1>
  @if (loading()) {
  <app-loading-spinner [minimal]="true" />
  } @else {
    <app-last-edited [diagram]="diagram()"></app-last-edited>
  }
</div>
@if (formGroup) {
<form [formGroup]="formGroup" (input)="onInputChanged()">
  <div class="canvas-container">
    <div class="top-container">
      <section class="problem-and-alternatives dashed-container">
        <div class="problem">
          <h2>{{ "diagrams.lean-canvas.problem" | translate }}</h2>
          <textarea
            formControlName="problem"
            [rows]="8"
            [placeholder]="
              'diagrams.lean-canvas.problem-placeholder' | translate
            "
          ></textarea>
        </div>
        <div class="alternatives">
          <h2>{{ "diagrams.lean-canvas.alternatives" | translate }}</h2>
          <textarea
            formControlName="alternatives"
            [rows]="8"
            [placeholder]="
              'diagrams.lean-canvas.alternatives-placeholder' | translate
            "
          ></textarea>
        </div>
      </section>
      <section class="solution-and-metrics">
        <div class="solution dashed-container">
          <h2>{{ "diagrams.lean-canvas.solution" | translate }}</h2>
          <textarea
            formControlName="solution"
            [rows]="8"
            [placeholder]="
              'diagrams.lean-canvas.solution-placeholder' | translate
            "
          ></textarea>
        </div>
        <div class="metrics dashed-container">
          <h2>{{ "diagrams.lean-canvas.metrics" | translate }}</h2>
          <textarea
            formControlName="metrics"
            [rows]="8"
            [placeholder]="
              'diagrams.lean-canvas.metrics-placeholder' | translate
            "
          ></textarea>
        </div>
      </section>
      <section class="value-and-concept dashed-container">
        <div class="unique-value-proposition">
          <h2>
            {{ "diagrams.lean-canvas.unique-value-proposition" | translate }}
          </h2>
          <textarea
            formControlName="uniqueValueProposition"
            [rows]="8"
            [placeholder]="
              'diagrams.lean-canvas.proposition-placeholder' | translate
            "
          ></textarea>
        </div>
        <div class="high-level-concept">
          <h2>{{ "diagrams.lean-canvas.high-level-concept" | translate }}</h2>
          <textarea
            formControlName="highLevelConcept"
            [rows]="8"
            [placeholder]="
              'diagrams.lean-canvas.high-level-concept-placeholder' | translate
            "
          ></textarea>
        </div>
      </section>
      <section class="advantage-and-channels">
        <div class="unfair-advantage dashed-container">
          <h2>{{ "diagrams.lean-canvas.unfair-advantage" | translate }}</h2>
          <textarea
            formControlName="unfairAdvantage"
            [rows]="8"
            [placeholder]="
              'diagrams.lean-canvas.unfair-advantage-placeholder' | translate
            "
          ></textarea>
        </div>
        <div class="channels dashed-container">
          <h2>{{ "diagrams.lean-canvas.channels" | translate }}</h2>
          <textarea
            formControlName="channels"
            [rows]="8"
            [placeholder]="
              'diagrams.lean-canvas.channels-placeholder' | translate
            "
          ></textarea>
        </div>
      </section>
      <section class="customer-segments-and-early-adopters dashed-container">
        <div class="customer-segments">
          <h2>{{ "diagrams.lean-canvas.customer-segments" | translate }}</h2>
          <textarea
            formControlName="customerSegments"
            [rows]="8"
            [placeholder]="
              'diagrams.lean-canvas.customer-segments-placeholder' | translate
            "
          ></textarea>
        </div>
        <div class="early-adopters">
          <h2>{{ "diagrams.lean-canvas.early-adopters" | translate }}</h2>
          <textarea
            formControlName="earlyAdopters"
            [rows]="8"
            [placeholder]="
              'diagrams.lean-canvas.early-adopters-placeholder' | translate
            "
          ></textarea>
        </div>
      </section>
    </div>
    <div class="bottom-container">
      <section class="cost dashed-container">
        <h2>{{ "diagrams.lean-canvas.cost-structure" | translate }}</h2>
        <textarea
          formControlName="costStructure"
          [rows]="5"
          [placeholder]="
            'diagrams.lean-canvas.cost-structure-placeholder' | translate
          "
        ></textarea>
      </section>
      <section class="revenue dashed-container">
        <h2>{{ "diagrams.lean-canvas.revenue-streams" | translate }}</h2>
        <textarea
          formControlName="revenueStreams"
          [rows]="5"
          [placeholder]="
            'diagrams.lean-canvas.revenue-streams-placeholder' | translate
          "
        ></textarea>
      </section>
    </div>
  </div>
</form>
}
