<div class="customer-detail-wrapper">
@if (!fields) {
  <app-loading-spinner></app-loading-spinner>
} @else {
  <app-form
  [titleKey]="'admin.customers.detail.edit.title'"
  [fields]="fields"
  (submit)="save($event)"
  (cancel)="cancel()"
></app-form>
}
</div>
