<div class="data-point">
  <h4 class="data-point__label">{{ labelKey | translate }}</h4>
  @switch(type) {
    @case('text') {
      <p class="data-point__value">{{ value }}</p>
    }
    @case('date') {
      <p class="data-point__value">{{ value | date: 'dd.MM.yyyy' }}</p>
    }
    @case('datetime') {
      <p class="data-point__value">{{ value | date: 'dd.MM.yyyy HH:mm' }}</p>
    }
    @case ('currency') {
      <p class="data-point__value">{{ value | currency }}</p>
    }
    @case ('percentage') {
      <progress class="data-point__value" [value]="value" max="100">{{ value | number: "0.00" }}%</progress>
    }
    @case ('boolean') {
      <input type="checkbox" [checked]="value" readonly="readonly" disabled/>
    }
    @case ('number') {
      <p class="data-point__value">{{ value | number }}</p>
    }
  }
  @if (!value) {
    <p class="data-point__value data-point__value--empty">{{ 'generic.dataPoint.empty' | translate }}</p>
  }
</div>
