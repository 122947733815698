<div
  class="loading-container"
  [class.fill-display]="!minimal"
  [class.minimal]="minimal"
  [title]="'generic.loading' | translate"
>
  <img
    class="loading-spinner"
    src="images/flowstruct-logo.png"
    [alt]="'generic.loading' | translate"
  />
  @if (!minimal) {
  <p>{{ "generic.loading" | translate }}</p>
  }
</div>
