import { Component, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChartArea, faHandHoldingDollar, faInfo, faSection, faUserGroup, faUserLock, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { CurrentUserStore } from '../../auth/current-user/current-user.store';
import { FeatureDisplayDirective } from '../../shared/features/feature-display.directive';
import { FeatureStore } from '../../shared/features/feature.store';
import { TestVersionLegendComponent } from '../../shared/test-version-legend/test-version-legend.component';

@Component({
    selector: 'app-user-menu',
    imports: [RouterModule, TranslateModule, FontAwesomeModule, FeatureDisplayDirective, TestVersionLegendComponent],
    templateUrl: './user-menu.component.html',
    styleUrl: './user-menu.component.scss'
})
export class UserMenuComponent {
  featureStore = inject(FeatureStore);
  currentUserStore = inject(CurrentUserStore);

  faUserGroup = faUserGroup;
  faUserLock = faUserLock;
  faChartArea = faChartArea;
  faUsersGear = faUsersGear;
  faHandHoldingDollar = faHandHoldingDollar;
  faSection = faSection;
  faInfo = faInfo;

  menuCollapsed = input(false);
}
