<h1>{{ "workgroups.title" | translate }}</h1>
<div class="user-workgroups-btn-group">
  <button [style.display]="currentUserStore.isElevatedUser() ? 'block' : 'none'" class="btn-success" (click)="toggleShowAddDialog()">
    <fa-icon [icon]="faPlus"></fa-icon>
    {{ "workgroups.add" | translate }}
  </button>
</div>
@if (loading) {
<app-loading-spinner />
} @else {
<app-data-table
  [columns]="columns"
  [data]="rows"
  [onSelectRow]="navigateToDetail"
/>
}
<app-add-workgroup
  [showDialog]="showAddDialog"
  (workgroupAdded)="onWorkgroupAdded()"
  (showDialogChange)="toggleShowAddDialog()"
/>
