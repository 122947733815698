import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnInit, OnDestroy {
  @Input() dialogTitle!: string;
  @Input() showDialog!: boolean;
  @ViewChild('appDialog', { static: true }) dialog!: ElementRef<HTMLDialogElement>;
  cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  ngOnChanges(): void {
    if (this.showDialog) {
      this.dialog.nativeElement.showModal();
    } else {
      this.dialog.nativeElement.close();
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.dialog.nativeElement.close();
    this.cdr.detectChanges();
  }
}
