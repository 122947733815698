import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { RegisterRepository } from '../register.repository';
import { RegisterStore } from '../register.store';

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule, LoadingSpinnerComponent],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss',
})
export class PaymentComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  registerStore = inject(RegisterStore);
  registerRepository = inject(RegisterRepository);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);

  checkoutLink = signal<string | undefined>(undefined);
  activatingAccount = signal<boolean>(false);
  activationError = signal<boolean>(false);

  faEuroSign = faEuroSign;

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams["cancel"] === "true") {
      this.router.navigate(['/payment-canceled']);
    }

    if (this.activatedRoute.snapshot.queryParams["sessionId"]) {
      this.confirmPaymentAndProceed();
      return;
    }

    if (!this.registerStore.paymentCompleted()) {
      this.setCheckoutLink();
      return;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setCheckoutLink() {
    this.registerRepository
        .getCheckoutSession(
          this.registerStore.customerId()!,
          this.registerStore.selectedSku()!,
          this.registerStore.addCustomerParam()!.givenUserCount
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (result) => {
            this.checkoutLink.set(result.sessionUrl);
          },
          error: (err) => {
            console.error(err);
          },
        });
  }

  confirmPaymentAndProceed() {
    this.activatingAccount.set(true);

    this.registerRepository.confirmPayment(this.activatedRoute.snapshot.queryParams["sessionId"], this.registerStore.customerId()!)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: () => {
        location.href = '/register';
        this.registerStore.setPaymentCompleted();
        this.activatingAccount.set(false);
        this.registerStore.setDone();
        this.registerStore.proceedToDoneStep();
      }, error: (err) => {
        this.activatingAccount.set(false);
        this.activationError.set(true);
      }
    });


  }

  goBack() {
    this.registerStore.goBack();
  }
}
