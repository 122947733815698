@if (diagramType() && diagram()) {
  @switch(diagramType()) {
    @case('priority') {
      <app-priority [diagramId]="diagramId()"/>
    }
    @case('lean-canvas') {
      <app-lean-canvas [diagramId]="diagramId()"/>
    }
    @case ('kanban') {
      <app-kanban [diagramId]="diagramId()"/>
    }
    @case ('risk-matrix') {
      <app-risk-matrix [diagramId]="diagramId()"/>
    }
  }
}
