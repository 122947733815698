import { DatePipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { SubscriptionRepository } from '../renew-subscription/subscription.repository';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { FeatureStore } from '../shared/features/feature.store';
import { LoadingSpinnerComponent } from '../shared/loading-spinner/loading-spinner.component';
import { ManageSubscriptionRepository } from './manage-subscription.repository';
import { ManageSubscriptionStore } from './manage-subscription.store';

@Component({
  selector: 'app-manage-subscription',
  standalone: true,
  imports: [TranslateModule, LoadingSpinnerComponent, DatePipe, DialogComponent],
  templateUrl: './manage-subscription.component.html',
  styleUrl: './manage-subscription.component.scss'
})
export class ManageSubscriptionComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();

  featureStore = inject(FeatureStore);
  store = inject(ManageSubscriptionStore);

  subscriptionRepository = inject(SubscriptionRepository);
  manageSubscriptionRepository = inject(ManageSubscriptionRepository);

  ngOnInit(): void {
    this.featureStore.getFeatures();

    this.loadCustomer();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCustomer() {
    this.store.setLoading();
    this.subscriptionRepository
    .getCurrentCustomer()
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (customer) => {
        if (customer.subscriptionCanceled) {
          this.store.cancelSubscription(customer.periodEndsAt!);
          this.store.stopLoading();
        }
      },
      error: (error) => {
        this.store.stopLoading();
      }
    });
  }

  toggleCancelDialog(): void {
    this.store.toggleCancelDialog();
  }

  cancelSubscription(): void {
    this.store.setLoading();
    this.manageSubscriptionRepository
    .cancelSubscription()
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (date) => {
        this.toggleCancelDialog();
        this.store.cancelSubscription(date);
        this.store.stopLoading();
      },
      error: (error) => {
        this.store.toggleCancelDialog();
        this.store.stopLoading();
        console.error(error);
      }
    });
  }
}
