import { Directive, ElementRef, inject, input, OnInit } from '@angular/core';
import { Sku } from '../../models/enums/sku.enum';
import { FeatureStore } from './feature.store';

@Directive({
  selector: '[appFeatureDisplay]',
  standalone: true
})
export class FeatureDisplayDirective implements OnInit {
  featureStore = inject(FeatureStore);
  minimumFeatureSku = input<Sku>(Sku.Enterprise);

  constructor(private readonly el: ElementRef) {}

  ngOnInit(): void {
    if (this.featureStore.customerSku() < this.minimumFeatureSku()) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
