@if (currentUserStore.isTestVersion()) {
<span class="version-legend" [style.height.px]="height()"
[class]="'sku' + sku()"
[title]="(isDiagramButton() ? 'test-version-legend.max-amount-of-diagrams' : 'test-version-legend.explanation.' + sku()) | translate ">
  @switch(sku()) {
    @case (1) {
      S
    }
    @case (2) {
      P
    }
    @case (3) {
      E
    }
  }
</span>
}
