import { Component, inject, OnInit, signal } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBuildingUser, faPlus, faPowerOff, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUserStore } from '../../auth/current-user/current-user.store';
import { DataTableColumn } from '../../shared/data-table/data-table-column';
import { DataTableComponent } from '../../shared/data-table/data-table.component';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { UserState } from '../user.model';
import { ChangeRoleDialogComponent } from "./change-role-dialog/change-role-dialog.component";
import { CustomerUserRepository } from './customer-user.repository';
import { CustomerUsersStore } from './customer-users.store';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { UserInformationComponent } from './user-information/user-information.component';

@Component({
  selector: 'app-customer-users',
  standalone: true,
  imports: [
    TranslateModule,
    FontAwesomeModule,
    DataTableComponent,
    UserInformationComponent,
    LoadingSpinnerComponent,
    InviteUserComponent,
    ChangeRoleDialogComponent
],
  templateUrl: './customer-users.component.html',
  styleUrl: './customer-users.component.scss',
})
export class CustomerUsersComponent implements OnInit {
  destroy$ = new Subject<void>();

  showAddUserDialog = signal<boolean>(false);
  showChangeRoleDialog = signal<boolean>(false);
  currentlySelectedUser = signal<{ userId: string, role: string }| undefined>(undefined);

  faPlus = faPlus;
  faBuildingUser = faBuildingUser;
  faTrash = faTrash;
  faPowerOff = faPowerOff;

  customerUsersStore = inject(CustomerUsersStore);
  customerUsersRepository = inject(CustomerUserRepository);
  currentUserStore = inject(CurrentUserStore);
  tranlateService = inject(TranslateService);

  columns: DataTableColumn[] = [
    {
      prop: 'username',
      name: 'Username',
      translateKey: 'organization-users.table.username',
      type: 'text',
    },
    {
      prop: 'emailAddress',
      name: 'Email',
      translateKey: 'organization-users.table.email',
      type: 'text',
    },
    {
      prop: 'role',
      name: 'Role',
      translateKey: 'organization-users.table.role',
      type: 'enum',
      enumStates: [
        {
          forValue: 'CustomerAdmin',
          class: 'secondary',
          textKey: 'organization-users.table.customerAdmin',
        },
        {
          forValue: 'User',
          class: 'light',
          textKey: 'organization-users.table.user',
        },
        {
          forValue: 'CustomerManager',
          class: 'primary',
          textKey: 'organization-users.table.customerManager',
        }
      ]
    },
    {
      prop: 'createdAt',
      name: 'Created At',
      translateKey: 'organization-users.table.createdAt',
      type: 'datetime',
    },
    {
      prop: 'updatedAt',
      name: 'Updated At',
      translateKey: 'organization-users.table.updatedAt',
      type: 'datetime',
    },
    {
      prop: 'userState',
      name: 'userState',
      translateKey: 'organization-users.table.state',
      type: 'enum',
      enumStates: [
        {
          forValue: 0,
          class: 'warning',
          textKey: 'organization-users.table.invited',
        },
        {
          forValue: 1,
          class: 'danger',
          textKey: 'organization-users.table.inactive',
        },
        {
          forValue: 2,
          class: 'success',
          textKey: 'organization-users.table.active',
        },
      ],
    },
    {
      prop: 'actions',
      name: 'Actions',
      translateKey: 'organization-users.table.actions',
      type: 'actions',
      actions: [
        {
          tooltip: 'Toggle Active',
          func: (row: any) => {
            const cell = row.cells.find((c: any) => c.column === 'userState');
            this.toggleUserActive(row.id, cell.value);
          },
          buttonClassFn: (row: any) => {
            const cell = row.cells.find((c: any) => c.column === 'userState');
            if (cell.value === 0 || row.id === this.currentUserStore.id()) {
              return 'no-display';
            }
            return cell.value > 1 ? 'btn-warning' : 'btn-success';
          },
          isDisabledFn: (row: any) => {
            const cell = row.cells.find((c: any) => c.column === 'userState');
            return this.customerUsersStore.maxUsersReached() && cell.value === 1;
          },
          buttonTextKeyFn: (row: any) => {
            const cell = row.cells.find((c: any) => c.column === 'userState');
            return cell.value > 1
              ? 'organization-users.table.deactivate'
              : 'organization-users.table.activate';
          },
          icon: faPowerOff,
        },
        {
          tooltip: 'Change role',
          func: (row: any) => {
            const cell = row.cells.find((c: any) => c.column === 'role');
            this.changeRole(row.id, cell.value);
          },
          buttonClassFn: (row: any) => {
            const cell = row.cells.find((c: any) => c.column === 'userState');
            if (cell.value === 0 || row.id === this.currentUserStore.id()) {
              return 'no-display';
            } else return 'btn-dark';
          },
          buttonTextKey: 'organization-users.table.change-role',
          icon: faBuildingUser
        },
        {
          tooltip: 'Delete user',
          func: (row: any) => {
            const cell = row.cells.find((c: any) => c.column === 'username');
            this.deleteUser(row.id, cell.value);
          },
          buttonClassFn: (row: any) => {
            const cell = row.cells.find((c: any) => c.column === 'userState');
            if (cell.value === 0 || row.id === this.currentUserStore.id()) {
              return 'no-display';
            } else return 'btn-danger';
          },
          buttonTextKey: '',
          icon: faTrash
        }
      ],
    },
  ];

  ngOnInit() {
    this.load();
  }

  load() {
    this.customerUsersStore.load();
  }

  onAddUser() {
    this.showAddUserDialog.set(true);
  }

  onSubmit() {
    this.showAddUserDialog.set(false);
    this.load();
  }

  onCancel() {
    this.showAddUserDialog.set(false);
  }

  toggleUserActive(userId: string, state: UserState) {
    this.customerUsersRepository.toggleActiveUser(userId)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: () => {
        this.load();
      },
    });
  }

  changeRole(userId: string, role: string) {
    this.currentlySelectedUser.set({ userId, role });
    this.showChangeRoleDialog.set(true);
  }

  onSubmitRoleChange() {
    this.currentlySelectedUser.set(undefined);
    this.showChangeRoleDialog.set(false);
    this.load();
  }

  onCancelRoleChange() {
    this.currentlySelectedUser.set(undefined);
    this.showChangeRoleDialog.set(false);
  }

  deleteUser(userId: string, userName: string) {
    this.tranlateService.get('organization-users.delete-user-confirm', { USER: userName})
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (message) => {
        if (confirm(message)) {
          this.customerUsersRepository.deleteUser(userId)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.load();
            },
          });
        }
      }
    });
  }
}
