import { CdkDragEnd, DragDropModule } from '@angular/cdk/drag-drop';
import { SlicePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';
import { clamp } from '../../shared/clamp';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { FormField } from '../../shared/form/form-field';
import { FormComponent } from '../../shared/form/form.component';
import { TooltipComponent } from '../../shared/tooltip/tooltip.component';
import { DiagramRepository } from '../diagram.repository';
import { DiagramModel } from '../models/diagram.model';
import { UpdateDiagramModel } from '../models/update-diagram.model';
import { RiskMatrixModel, RiskModel } from './risk-matrix.model';

@Component({
  selector: 'app-risk-matrix',
  imports: [
    TranslateModule,
    FontAwesomeModule,
    DialogComponent,
    FormComponent,
    DragDropModule,
    TooltipComponent,
    SlicePipe,
    ConfirmationDialogComponent,
  ],
  templateUrl: './risk-matrix.component.html',
  styleUrl: './risk-matrix.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskMatrixComponent implements OnInit {
  showAddRiskDialog = signal<boolean>(false);
  riskData = signal<RiskMatrixModel | undefined>(undefined);
  diagram = input<DiagramModel | undefined>(undefined);
  changed = output<UpdateDiagramModel>();

  showDeleteRiskDialog = signal<boolean>(false);
  deleteRiskId = signal<string | undefined>(undefined);

  faArrowLeft = faArrowLeft;
  faTriangleExclamation = faTriangleExclamation;
  faArrowRight = faArrowRight;
  faTrash = faTrash;

  fields: FormField[] = [
    {
      name: 'name',
      labelKey: 'diagrams.risk-matrix.dialog.name',
      fieldType: 'text',
      required: true,
    },
    {
      name: 'description',
      labelKey: 'diagrams.risk-matrix.dialog.description',
      fieldType: 'textarea',
      required: false,
    },
  ];

  constructor(
    private readonly diagramRepository: DiagramRepository,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const diagramData = JSON.parse(this.diagram()?.diagramJson ?? '{}');
    this.riskData.set(diagramData);
  }

  addRisk() {
    this.showAddRiskDialog.set(true);
  }

  updateDiagram() {
    const diagram = this.diagram();
    if (diagram) {
      const diagramData = { type: 'risk-matrix', ...this.riskData() };
      diagram.diagramJson = JSON.stringify(diagramData);
      this.changed.emit(diagram);
    }
  }

  backToDiagrams(): void {
    if (!this.diagram()?.projectId !== null) {
      this.router.navigate([
        'user/dashboard/',
        this.diagram()?.projectId ?? '',
      ]);
    } else {
      this.router.navigate(['user/dashboard/private']);
    }
  }

  onAddRisk($event: FormGroup) {
    const risk = $event.value;
    const risks = this.riskData()?.risks ?? [];
    risk.id = uuid();
    risk.position = { x: 0, y: 0 };
    risks.push(risk);
    this.riskData.set({ risks });
    this.showAddRiskDialog.set(false);
    this.updateDiagram();
  }

  onDragEnd(event: CdkDragEnd<RiskModel>) {
    const risks = this.riskData()?.risks ?? [];
    const risk = risks.find((r) => r.id === event.source.data.id);
    if (risk) {
      const parentElementBoundingRect =
        event.source.element.nativeElement.parentElement!.getBoundingClientRect();
      const clientElementBoundingRect =
        event.source.element.nativeElement.getBoundingClientRect();

      const x = clamp(
        ((clientElementBoundingRect.left - parentElementBoundingRect.left) /
          parentElementBoundingRect.width) *
          100,
        0,
        100
      );
      const y = clamp(
        ((clientElementBoundingRect.bottom - parentElementBoundingRect.bottom) /
          -parentElementBoundingRect.height) *
          100,
        0,
        100
      );

      risk.position = { x: x, y: y };

      this.riskData.set({ risks });
      event.source.reset();
      this.updateDiagram();
    }
  }

  onDeleteRisk(id: string): void {
    this.deleteRiskId.set(id);
    this.showDeleteRiskDialog.set(true);
  }

  onDeleteRiskConfirmed(): void {
    this.showDeleteRiskDialog.set(false);
    const risks = this.riskData()?.risks ?? [];
    const risk = risks.find((r) => r.id === this.deleteRiskId());
    if (risk) {
      const index = risks.indexOf(risk);
      risks.splice(index, 1);
      this.riskData.set({ risks });
      this.updateDiagram();
    }
    this.deleteRiskId.set(undefined);
  }

  onDeletedRiskCancel(): void {
    this.showDeleteRiskDialog.set(false);
    this.deleteRiskId.set(undefined);
  }

  onCancelAddRisk() {
    this.showAddRiskDialog.set(false);
  }
}
