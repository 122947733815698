import { Sku } from "../../../models/enums/sku.enum";
import { SkuModel } from "../sku.model";

export const skus: SkuModel[] = [
  {
    name: 'Basic',
    descriptionKey: 'sku.basic.description',
    price: 5.00,
    sku: Sku.Basic,
    features: [
      { nameKey: 'unauthenticated.register.features.data-encryption', included: true },
      { nameKey: 'unauthenticated.register.features.priority', included: true },
      { nameKey: 'unauthenticated.register.features.risk', included: true },
      { nameKey: 'unauthenticated.register.features.kanban', included: true },
      { nameKey: 'unauthenticated.register.features.lean-canvas', included: false },
      { nameKey: 'unauthenticated.register.features.projects', included: false },
      { nameKey: 'unauthenticated.register.features.user-management', included: false },
      { nameKey: 'unauthenticated.register.features.workgroups', included: false },
    ],
    maxAmountOfUsers: 1,
  },
  {
    name: 'Startup',
    descriptionKey: 'sku.startup.description',
    price: 8.50,
    sku: Sku.Startup,
    features: [
      { nameKey: 'unauthenticated.register.features.data-encryption', included: true },
      { nameKey: 'unauthenticated.register.features.risk', included: true },
      { nameKey: 'unauthenticated.register.features.priority', included: true },
      { nameKey: 'unauthenticated.register.features.kanban', included: true },
      { nameKey: 'unauthenticated.register.features.lean-canvas', included: true },
      { nameKey: 'unauthenticated.register.features.projects', included: true },
      { nameKey: 'unauthenticated.register.features.user-management', included: true },
      { nameKey: 'unauthenticated.register.features.workgroups', included: false },
    ],
    maxAmountOfUsers: 50,
  },
  {
    name: 'Pro',
    descriptionKey: 'sku.pro.description',
    price: 10.00,
    sku: Sku.Pro,
    features: [
      { nameKey: 'unauthenticated.register.features.data-encryption', included: true },
      { nameKey: 'unauthenticated.register.features.priority', included: true },
      { nameKey: 'unauthenticated.register.features.risk', included: true },
      { nameKey: 'unauthenticated.register.features.kanban', included: true },
      { nameKey: 'unauthenticated.register.features.lean-canvas', included: true },
      { nameKey: 'unauthenticated.register.features.projects', included: true },
      { nameKey: 'unauthenticated.register.features.user-management', included: true },
      { nameKey: 'unauthenticated.register.features.workgroups', included: true },
    ],
    maxAmountOfUsers: 1000,
  },
];
