import { withDevtools, withStorageSync } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Sku } from '../../models/enums/sku.enum';
import { HttpService } from '../http.service';
import { CustomerFeatureModel, FeatureModel } from './feature.model';

const apiConfig = environment.apiConfig;

export type FeatureState = {
  features: FeatureModel[];
  customerSku: Sku;
};

const initialState: FeatureState = {
  features: [],
  customerSku: Sku.Basic,
};

export const FeatureStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withStorageSync('feature'),
  withComputed(({ features, customerSku }) => ({
    hasUnlimitedProjects: computed(() =>
      hasFeatureAccess('unlimitedProjects', customerSku(), features())
    ),
    hasWorkgroups: computed(() =>
      hasFeatureAccess('workgroups', customerSku(), features())
    ),
    hasAdvancedUserManagement: computed(() =>
      hasFeatureAccess('advancedUserManagement', customerSku(), features())
    ),
    hasRiskMatrix: computed(() =>
      hasFeatureAccess('riskMatrix', customerSku(), features())
    ),
    hasKanban: computed(() =>
      hasFeatureAccess('kanban', customerSku(), features())
    ),
    hasPriorityMatrix: computed(() =>
      hasFeatureAccess('priorityMatrix', customerSku(), features())
    ),
    hasLeanCanvas: computed(() =>
      hasFeatureAccess('leanCanvas', customerSku(), features())
    ),
    hasMilestones: computed(() =>
      hasFeatureAccess('milestones', customerSku(), features())
    ),
    hasFocusTree: computed(() =>
      hasFeatureAccess('focusTree', customerSku(), features())
    ),
    isBasic: computed(() => customerSku() === Sku.Basic),
    isStartup: computed(() => customerSku() === Sku.Startup),
    isPro: computed(() => customerSku() === Sku.Pro),
  })),
  withMethods((store, httpService = inject(HttpService)) => ({
    getFeatures(): Observable<void> {
      return new Observable<void>((observer) => {
        httpService
          .get<CustomerFeatureModel>(apiConfig.url + `/feature/all`)
          .subscribe({
            next: (customerFeatureModel) => {
              patchState(store, {
                features: customerFeatureModel.features,
                customerSku: customerFeatureModel.customerSku,
              });
              observer.next();
              observer.complete();
            },
            error: () => {
              observer.error();
            },
          });
      });
    },
    hasFeature(feature: string): boolean {
      return store.features().some((f) => f.key === feature && f.sku <= store.customerSku());
    },
    reset() {
      patchState(store, initialState);
    }
  })),
  withDevtools('featureStore')
);

const hasFeatureAccess = (
  feature: string,
  sku: Sku,
  features: FeatureModel[]
) => {
  return features.some((f) => f.key === feature && f.sku <= sku);
};
