import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpService } from '../shared/http.service';
import { UserModel } from '../user/user.model';
import { WorkgroupAddUserUpdateParam } from './add-user-workgroup/add-user-update-param';
import { WorkgroupList, WorkgroupModel } from './workgroup.model';

const apiConfig = environment.apiConfig;

@Injectable({
  providedIn: 'root',
})
export class WorkgroupRepository {

  constructor(private readonly httpService: HttpService) {}

  getWorkgroups(): Observable<WorkgroupList> {
    return this.httpService.get<WorkgroupList>(
      `${apiConfig.url}/workgroup/our`
    );
  }

  getById(id: string): Observable<WorkgroupModel> {
    return this.httpService.get<WorkgroupModel>(
      `${apiConfig.url}/workgroup/${id}`
    );
  }

  addWorkgroup(value: string | null, userIds: string[]): Observable<void> {
    return this.httpService.post<void>(`${apiConfig.url}/workgroup/create`, {
      name: value,
      toBeInvitedUserIds: userIds,
    });
  }

  getCustomerUsers(): Observable<UserModel[]> {
    return this.httpService.get<any>(`${apiConfig.url}/customer/users`);
  }

  getWorkgroupUsers(id: string): Observable<UserModel[]> {
    return this.httpService.get<any>(`${apiConfig.url}/workgroup/${id}/users`);
  }

  updateWorkgroupUsers(id: string, param: WorkgroupAddUserUpdateParam): Observable<void> {
    return this.httpService.put<void>(
      `${apiConfig.url}/workgroup/${id}/users`,
      param
    );
  }

  updateWorkgroup(id: string, name: string): Observable<void> {
    return this.httpService.put<void>(`${apiConfig.url}/workgroup/${id}`, {
      name,
    });
  }

  deleteWorkgroup(id: string): Observable<void> {
    return this.httpService.delete<void>(`${apiConfig.url}/workgroup/${id}`);
  }
}
