import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, inject, input, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArrowLeft,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { UserMenuStore } from '../../user/user/user-menu.store';
import { DiagramRepository } from '../diagram.repository';
import { LastEditedComponent } from '../last-edited/last-edited.component';
import { DiagramModel } from '../models/diagram.model';
import { UpdateDiagramModel } from '../models/update-diagram.model';
import { AddPriorityIssueComponent } from './add-priority-issue/add-priority-issue.component';
import { NewPriorityIssue } from './add-priority-issue/new-priority-issue';
import { PriorityIssue } from './priority-issue';
import { PriorityIssueComponent } from './priority-issue/priority-issue.component';
import { Priority, PriorityHelper } from './priority.enum';
import { PriorityModel } from './priority.model';
import { PriorityService } from './priority.service';

@Component({
  selector: 'app-priority',
  standalone: true,
  imports: [
    TranslateModule,
    FontAwesomeModule,
    PriorityIssueComponent,
    CdkDrag,
    CdkDropList,
    AddPriorityIssueComponent,
    LoadingSpinnerComponent,
    RouterModule,
    LastEditedComponent
  ],
  templateUrl: './priority.component.html',
  styleUrl: './priority.component.scss',
})
export class PriorityComponent implements OnInit, OnDestroy {
  $destroy = new Subject<void>();
  loading = false;
  diagramName = '';
  userMenuStore = inject(UserMenuStore);

  diagramId = input<string | undefined>(undefined);

  ngOnInit(): void {
    this.loadPriorityData();
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  faPlus = faPlus;
  faTrash = faTrash;
  faArrowLeft = faArrowLeft;

  draggedIssue = undefined;
  dialogPriority = Priority.None;
  dialogVisible = false;
  diagramData?: DiagramModel;

  aIssues: PriorityIssue[] = [];
  bIssues: PriorityIssue[] = [];
  cIssues: PriorityIssue[] = [];
  dIssues: PriorityIssue[] = [];
  doneIssues: PriorityIssue[] = [];
  removedIssues: PriorityIssue[] = [];

  protected get doneOrRemovedIssues(): PriorityIssue[] {
    const combined = [...this.doneIssues, ...this.removedIssues];
    combined.sort((a, b) => {
      if (a.updated && b.updated) {
        const sorted =
          new Date(b.updated)?.getTime() ??
          0 - new Date(a.updated)?.getTime() ??
          0;
        if (sorted > 0) {
          return -1;
        } else if (sorted < 0) {
          return 1;
        } else {
          return 0;
        }
      }
      return 0;
    });
    return combined;
  }

  constructor(
    private readonly priorityService: PriorityService,
    private readonly diagramRepository: DiagramRepository,
    private readonly router: Router
  ) {}

  loadPriorityData(): void {
    if (!this.diagramId()) {
      return;
    }

    this.loading = true;
    if (!this.diagramId) {
      this.loading = false;
      return;
    }

    this.diagramRepository
      .get(this.diagramId()!)
      .pipe(takeUntil(this.$destroy))
      .subscribe((result) => {
        const data = this.priorityService.deserializePriorityData(
          result.diagramJson
        );
        this.aIssues = data.aIssues ?? [];
        this.bIssues = data.bIssues ?? [];
        this.cIssues = data.cIssues ?? [];
        this.dIssues = data.dIssues ?? [];
        this.doneIssues = data.doneIssues ?? [];
        this.removedIssues = data.removedIssues ?? [];
        this.diagramName = result.name;
        this.loading = false;
        this.diagramData = result;
      });
  }

  drop(event: CdkDragDrop<PriorityIssue[]>, zone: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray<PriorityIssue>(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    const foundIssue = event.container.data[event.currentIndex];

    switch (zone) {
      case 'A':
        foundIssue!.priority = Priority.A;
        break;
      case 'B':
        foundIssue!.priority = Priority.B;
        break;
      case 'C':
        foundIssue!.priority = Priority.C;
        break;
      case 'D':
        foundIssue!.priority = Priority.D;
        break;
      case 'None':
        foundIssue!.priority = Priority.None;
        break;
    }
    this.updatePriority();
  }

  showDialog(priority: string): void {
    this.dialogVisible = true;
    this.dialogPriority = PriorityHelper.getPriorityFromString(priority);
  }

  onDialogCanceled(): void {
    this.dialogVisible = false;
  }

  onAddedPriority(issue: NewPriorityIssue) {
    const createdIssue: PriorityIssue = {
      id: uuid(),
      title: issue.title,
      description: issue.description!,
      priority: issue.priority,
      updated: new Date(),
      state: 'active',
    };
    switch (issue.priority) {
      case Priority.A:
        this.aIssues.push(createdIssue);
        break;
      case Priority.B:
        this.bIssues.push(createdIssue);
        break;
      case Priority.C:
        this.cIssues.push(createdIssue);
        break;
      case Priority.D:
        this.dIssues.push(createdIssue);
        break;
      default:
        break;
    }
    this.dialogVisible = false;

    this.updatePriority();
  }

  updatePriority() {
    const data: PriorityModel = {
      aIssues: this.aIssues,
      bIssues: this.bIssues,
      cIssues: this.cIssues,
      dIssues: this.dIssues,
      doneIssues: this.doneIssues,
      removedIssues: this.removedIssues,
      type: 'priority',
    };
    const updateParam: UpdateDiagramModel = {
      id: this.diagramData?.id!,
      name: this.diagramName,
      diagramJson: this.priorityService.serializePriorityData(data),
    };
    this.diagramRepository
      .update(updateParam)
      .pipe(takeUntil(this.$destroy))
      .subscribe(() => {
        this.loadPriorityData();
      });
  }

  onBack() {
    if (!this.diagramData?.projectId !== null) {
      this.router.navigate([
        'user/dashboard/',
        this.diagramData?.projectId ?? '',
      ]);
    } else {
      this.router.navigate(['user/dashboard/private']);
    }
  }

  removeIssue(issue: PriorityIssue, originZone: string) {
    switch (originZone) {
      case 'A':
        this.aIssues = this.aIssues.filter((x) => x.id !== issue.id);
        break;
      case 'B':
        this.bIssues = this.bIssues.filter((x) => x.id !== issue.id);
        break;
      case 'C':
        this.cIssues = this.cIssues.filter((x) => x.id !== issue.id);
        break;
      case 'D':
        this.dIssues = this.dIssues.filter((x) => x.id !== issue.id);
        break;
    }

    issue.updated = new Date();
    issue.state = 'removed';
    this.removedIssues.push(issue);
    this.updatePriority();
  }

  removeIssueForever(issue: PriorityIssue) {
    this.removedIssues = this.removedIssues.filter((x) => x.id !== issue.id);
    this.doneIssues = this.doneIssues.filter((x) => x.id !== issue.id);
    this.updatePriority();
  }

  markAsDone(issue: PriorityIssue) {
    issue.updated = new Date();
    switch (issue.priority) {
      case Priority.A:
        this.aIssues = this.aIssues.filter((x) => x.id !== issue.id);
        break;
      case Priority.B:
        this.bIssues = this.bIssues.filter((x) => x.id !== issue.id);
        break;
      case Priority.C:
        this.cIssues = this.cIssues.filter((x) => x.id !== issue.id);
        break;
      case Priority.D:
        this.dIssues = this.dIssues.filter((x) => x.id !== issue.id);
        break;
      case Priority.None:
        break;
    }

    issue.state = 'done';
    this.doneIssues.push(issue);
    this.updatePriority();
  }
}
