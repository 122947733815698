import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  output
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { FeatureDisplayDirective } from '../../shared/features/feature-display.directive';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { TestVersionLegendComponent } from "../../shared/test-version-legend/test-version-legend.component";
import { ProjectModel } from '../project.model';
import { ProjectRepository } from '../project.repository';

@Component({
  selector: 'app-project-selector',
  standalone: true,
  imports: [TranslateModule, LoadingSpinnerComponent, FormsModule, TestVersionLegendComponent, FeatureDisplayDirective],
  templateUrl: './project-selector.component.html',
  styleUrl: './project-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSelectorComponent implements OnInit, OnDestroy {
  projectChanged = output<string>();

  destroy$ = new Subject<void>();
  projects: ProjectModel[] = [];
  selectedProjectId: string = '';
  loading = false;

  private _projectIdFromRoute: string | undefined | null = undefined;
  set projectIdFromRoute(value: string | undefined | null) {
    this._projectIdFromRoute = value;
  }
  get projectIdFromRoute(): string | undefined | null {
    return this._projectIdFromRoute;
  }

  constructor(
    private readonly projectRepository: ProjectRepository,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.loadProjects();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadProjects(): void {
    this.loading = true;
    this.projectRepository
      .getOurProjects()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result) => {
          this.projects = result;
          this.loading = false;
          this.preselectFromRoute();
        },
        error: (error) => {
          this.loading = false;
        },
      });
  }

  onSelect($event: any): void {
    this.location.replaceState('/user/dashboard/' + $event.target.value);
    this.projectIdFromRoute = $event.target.value;

    this.projectChanged.emit(this.selectedProjectId);
  }

  preselectFromRoute(): void {
    this.projectIdFromRoute =
      this.activatedRoute.snapshot.params['projectId'] || null || undefined;
    if (this.projectIdFromRoute !== null && this.projectIdFromRoute !== undefined && this.projectIdFromRoute !== 'private') {
      this.selectedProjectId = this.projects.find(
        (project) => project.id === this.projectIdFromRoute
      )?.id ?? 'private';
    } else {
      this.location.replaceState('/user/dashboard/private');
      this.selectedProjectId = 'private';
    }
    this.projectChanged.emit(this.selectedProjectId);
  }
}
