export const environment = {
  //...
  // msal config details
  msalConfig: {
    clientId: 'df640771-7aff-4bb7-b78c-799a4929b0bf',
    authority: 'https://login.microsoftonline.com/organizations',
    tenantId: 'organizations',
    redirectUri: 'https://dev.flowstruct.de/home',
    postLogoutRedirectUri: 'https://dev.flowstruct.de/home',
  },
  apiConfig: {
    url: 'https://dev-flowstructapi-app.azurewebsites.net/api',
    scopes: ['api://flowstruct-dev-api/api.access'],
  },
  "stripe": {
    "apiKey": "pk_test_51QAU57LPT9rG39lI0lkm2yRcaGGUhYEd3qC3DeC3g7PyeO5ftWsUllfJnjRQNOgkPJPiAXvBkVFw6I8qLdj1IhBv00hvD15uvT"
  }
};
