<div class="register-background">
  @switch(registerStore.step()) { @case('sku') {
  <app-sku-selection />
  } @case('customer') {
  <app-data-input />
  } @case('confirmation') {
    <app-check-data />
  }
  @case ('payment') {
  <app-payment />
  } @case('done') {
  <app-registration-done />
  }
}
<app-progress-display />
<div class="language-selection">
  <app-language-switch />
</div>
</div>
