import { withDevtools, withStorageSync } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

type UserMenuStore = {
  collapsed: boolean;
};

const initialState: UserMenuStore = {
  collapsed: false,
};

export const UserMenuStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store) => ({
    toggleCollapsed() {
      patchState(store, { collapsed: !store.collapsed() });
    },
  })),
  withStorageSync('user-menu-store'),
  withDevtools('UserMenuStore')
);
