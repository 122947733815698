import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ErrorService } from './error/error.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  get<T>(url: string, options?: {}): Observable<T> {
    return this.httpClient.get<T>(url, options).pipe(
      catchError((error: HttpErrorResponse, caught: Observable<T>) => {
        if (error.status === 404) {
          this.errorService.setError('not-found', error.error);
        } else if (error.status === 401 || error.status === 403) {
          this.errorService.setError('authorization', error.error);
        } else if (error.status === 402) {
          this.errorService.setError('subscription', error.error);
        } else if (error.status === 0) {
          this.errorService.setError('connection', 'errors.no-connection');
        } else if (error.status === 400) {
          this.errorService.setError('input', error.error);
        }  else if (error.status === 452) {
          this.errorService.setError('no-customer', error.error);
        } else {
          this.errorService.setError('unknown', error.error);
        }
        return throwError(() => {
          console.error(error);
          return new Error(error.error);
        });
      })
    );
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(url, body).pipe(
      catchError((error: HttpErrorResponse, caught: Observable<T>) => {
        if (error.status === 404) {
          this.errorService.setError('not-found', error.error);
        } else if (error.status === 401 || error.status === 403) {
          this.errorService.setError('authorization', error.error);
        } else if (error.status === 402) {
          this.errorService.setError('subscription', error.error);
        } else if (error.status === 0) {
          this.errorService.setError('connection', 'errors.no-connection');
        } else if (error.status === 400) {
          this.errorService.setError('input', error.error);
        } else if (error.status === 452) {
          this.errorService.setError('no-customer', error.error);
        } else {
          this.errorService.setError('unknown', error.error);
        }
        return throwError(() => new Error(error.error));
      })
    );
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.httpClient.put<T>(url, body).pipe(
      catchError((error: HttpErrorResponse, caught: Observable<T>) => {
        if (error.status === 404) {
          this.errorService.setError('not-found', error.error);
        } else if (error.status === 401 || error.status === 403) {
          this.errorService.setError('authorization', error.error);
        } else if (error.status === 402) {
          this.errorService.setError('subscription', error.error);
        } else if (error.status === 0) {
          this.errorService.setError('connection', 'errors.no-connection');
        } else if (error.status === 400) {
          this.errorService.setError('input', error.error);
        } else if (error.status === 452) {
          this.errorService.setError('no-customer', error.error);
        } else {
          this.errorService.setError('unknown', error.error);
        }
        return throwError(() => new Error(error.error));
      })
    );
  }

  delete<T>(url: string): Observable<T> {
    return this.httpClient.delete<T>(url).pipe(
      catchError((error: HttpErrorResponse, caught: Observable<T>) => {
        if (error.status === 404) {
          this.errorService.setError('not-found', error.error);
        } else if (error.status === 401 || error.status === 403) {
          this.errorService.setError('authorization', error.error);
        } else if (error.status === 402) {
          this.errorService.setError('subscription', error.error);
        } else if (error.status === 0) {
          this.errorService.setError('connection', 'errors.no-connection');
        } else if (error.status === 400) {
          this.errorService.setError('input', error.error);
        } else if (error.status === 452) {
          this.errorService.setError('no-customer', error.error);
        } else {
          this.errorService.setError('unknown', error.error);
        }
        return throwError(() => new Error(error.error));
      })
    );
  }
}
