import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  input,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MultiselectComponent } from '../multiselect/multiselect.component';
import { MultiselectStore } from '../multiselect/multiselect.store';
import { FormField } from './form-field';

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, MultiselectComponent],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnInit {
  multiSelectStore = inject(MultiselectStore);

  resetOnSubmit = input<boolean>(true);
  resetOnCancel = input<boolean>(true);
  submitClass = input<string>('btn-success');
  cancelClass = input<string>('btn-danger');
  submitTextKey = input<string>('generic.save');
  cancelTextKey = input<string>('generic.cancel');

  @Input({ required: true }) titleKey!: string;
  @Input({ required: true }) fields!: FormField[];

  @Output() submit = new EventEmitter<FormGroup>();
  @Output() cancel = new EventEmitter<void>();

  protected formGroup!: FormGroup;

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnChanges(): void {
    this.initializeForm();
  }

  initializeForm() {
    const formControls: { [key: string]: FormControl } = {};
    for (const field of this.fields) {
      if (!field.required) {
        switch (field.fieldType) {
          case 'multi-select':
            formControls[field.name] = new FormControl(
              field.defaultSelectedMultiSelectItems,
              field.validators
            );
            continue;
          default:
            formControls[field.name] = new FormControl(
              field.defaultValue,
              field.validators
            );
            continue;
        }
      }
      switch (field.fieldType) {
        case 'text':
        case 'password':
        case 'email':
          formControls[field.name] = new FormControl(
            field.defaultValue,
            field.validators
          );
          continue;
        case 'number':
          formControls[field.name] = new FormControl(
            field.defaultValue ?? field.minValue,
            field.validators
          );
          continue;
        case 'date':
          formControls[field.name] = new FormControl(
            field.defaultValue,
            field.validators
          );
          continue;
        case 'time':
          formControls[field.name] = new FormControl(
            field.defaultValue,
            field.validators
          );
          continue;
        case 'select':
          formControls[field.name] = new FormControl(
            field.selectOptions![0].value,
            field.validators
          );
          continue;
        case 'radio':
          formControls[field.name] = new FormControl(
            field.defaultValue,
            field.validators
          );
          continue;
        case 'checkbox':
          formControls[field.name] = new FormControl(
            field.defaultValue,
            field.validators
          );
          continue;
        case 'textarea':
          formControls[field.name] = new FormControl(
            field.defaultValue,
            field.validators
          );
          continue;
        case 'multi-select':
          formControls[field.name] = new FormControl(
            field.defaultSelectedMultiSelectItems,
            field.validators
          );
          continue;
        default:
          throw new Error(`Unsupported field type: ${field.fieldType}`);
      }
    }
    this.formGroup = this.formBuilder.group(formControls);
  }

  onSubmit() {
    this.submit.emit(this.formGroup);
    if (this.resetOnSubmit()) {
      this.multiSelectStore.setSelectedItems([]);
      this.formGroup.reset();
    }
  }

  onCancel() {
    if (this.resetOnCancel()) {
      this.formGroup.reset();
      this.multiSelectStore.setSelectedItems([]);
    }
    this.cancel.emit();
  }

  onSelectedItemsChanged(selectedItems: any[], field: FormField) {
    this.formGroup.get(field.name)!.setValue(selectedItems);
  }
}
