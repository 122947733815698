import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { ErrorComponent } from './shared/error/error.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ErrorComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  constructor(
    public translate: TranslateService,
    private readonly _msalService: MsalService
  ) {
    translate.addLangs(['en', 'de']);
    translate.setDefaultLang('de');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang?.match(/en|de/) ? browserLang : 'de');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this._msalService
      .handleRedirectObservable()
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe({
        next: (result) => {
          if (result && result.account && result.account.username) {
            this._msalService.instance.setActiveAccount(result.account);
          }
        },
        error: (error) => console.error(error),
      });
  }
}
