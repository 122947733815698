import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CurrentUserStore } from './current-user/current-user.store';

export const customerActivatedGuard: CanActivateFn = (route, state) => {
  const currentUserStore = inject(CurrentUserStore);
  const router = inject(Router);

  if (currentUserStore.customerActive()) {
    return true;
  } else {
    return router.createUrlTree(['/renew-subscription']);
  }
};
