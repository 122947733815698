import {
  Component,
  EventEmitter,
  inject,
  input,
  Input,
  OnDestroy,
  Output,
  signal,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { FeatureStore } from '../../shared/features/feature.store';
import { FormField } from '../../shared/form/form-field';
import { FormComponent } from '../../shared/form/form.component';
import { DiagramRepository } from '../diagram.repository';
import { DefaultValues } from '../models/diagram-default-values';

@Component({
  selector: 'app-new-diagram',
  standalone: true,
  imports: [DialogComponent, TranslateModule, FormComponent],
  templateUrl: './new-diagram.component.html',
  styleUrl: './new-diagram.component.scss',
})
export class NewDiagramComponent implements OnDestroy {
  $destroy = new Subject<void>();
  featureStore = inject(FeatureStore);
  @Input({ required: true }) showDialog = false;
  @Input() projectId?: string | null | undefined;
  privateOnly = input<boolean | undefined>(undefined);

  @Output() loading = new EventEmitter<boolean>();
  @Output() priorityMatrixCreated = new EventEmitter<string>();
  @Output() canceled = new EventEmitter<void>();

  fields = signal<FormField[]>([]);

  constructor(private readonly diagramRepository: DiagramRepository) {}

  ngOnInit() {
    console.log(this.privateOnly(), this.projectId);

    this.initializeFields();
  }

  ngOnChanges(): void {
    this.initializeFields();
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  initializeFields(): void {
    const availabeTypes: string[] = [];

    if (this.featureStore.hasKanban()) {
      availabeTypes.push('kanban');
    }
    if (this.featureStore.hasPriorityMatrix()) {
      availabeTypes.push('priority');
    }
    if (this.featureStore.hasLeanCanvas()) {
      availabeTypes.push('lean-canvas');
    }
    if (this.featureStore.hasRiskMatrix()) {
      availabeTypes.push('risk-matrix');
    }
    if (this.featureStore.hasFocusTree()) {
      availabeTypes.push('focus-tree');
    }
    if (this.featureStore.hasMilestones()) {
      availabeTypes.push('milestones');
    }

    this.fields.set([
      {
        name: 'name',
        labelKey: 'diagrams.new-diagram.name',
        fieldType: 'text',
        required: true,
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      },
      {
        name: 'type',
        labelKey: 'diagrams.new-diagram.type',
        fieldType: 'select',
        required: true,
        selectOptions: availabeTypes.map((type) => {
          return {
            key: type,
            translateKey: `diagrams.new-diagram.type-options.${type}`,
            value: type,
          };
        }),
        defaultValue: 'priority',
      },
    ]);

    if (!this.privateOnly()) {
      this.fields.set([
        ...this.fields(),
        {
          name: 'isPrivate',
          labelKey: 'diagrams.new-diagram.is-private',
          fieldType: 'checkbox',
          required: false,
          defaultValue: false,
        },
      ]);
    }
  }

  onSave(form: FormGroup): void {
    this.loading.emit(true);

    const type = form.get('type')!.value;

    let defaultValues = undefined;
    if (type === 'kanban') {
      defaultValues = new DefaultValues().kanban();
    }

    const diagramData = { type, ...defaultValues };
    const diagramJson = JSON.stringify(diagramData);

    this.diagramRepository
      .create({
        name: form.get('name')!.value,
        projectId:
          form.get('isPrivate')!.value || this.privateOnly()
            ? null
            : this.projectId,
        diagramJson,
      })
      .pipe(takeUntil(this.$destroy))
      .subscribe((result) => {
        this.loading.emit(false);
        this.priorityMatrixCreated.emit(result.id);
      });
  }

  onCancel(): void {
    this.canceled.emit();
  }
}
