import { Routes } from '@angular/router';
import { AdminCustomerDetailComponent } from './admin-customer-detail/admin-customer-detail.component';
import { AdminCustomerEditComponent } from './admin-customer-edit/admin-customer-edit.component';
import { AddCustomerComponent } from './admin-customers/add-customer/add-customer.component';
import { AdminCustomersComponent } from './admin-customers/admin-customers.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { authGuard } from './auth/auth.guard';
import { customerActivatedGuard } from './auth/customer-activated.guard';
import { DiagramViewerComponent } from './diagrams/diagram-viewer/diagram-viewer.component';
import { HomeComponent } from './home/home.component';
import { ManageSubscriptionComponent } from './manage-subscription/manage-subscription.component';
import { MyProjectsComponent } from './project/my-projects/my-projects.component';
import { RenewSubscriptionComponent } from './renew-subscription/renew-subscription.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { PaymentCanceledComponent } from './unauthenticated/register-wizard/payment-canceled/payment-canceled.component';
import { WizardContainerComponent } from './unauthenticated/register-wizard/wizard-container.component';
import { CustomerUsersComponent } from './user/customer-users/customer-users.component';
import { UserDashboardComponent } from './user/user-dashboard/user-dashboard.component';
import { UserComponent } from './user/user/user.component';
import { WorkgroupDetailComponent } from './workgroups/workgroup-detail/workgroup-detail.component';
import { WorkgroupListComponent } from './workgroups/workgroup-list.component';

export const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [authGuard],
    data: { roles: ['Admin'] },
    title: 'FlowStruct - Admin',
    children: [
      {
        path: 'dashboard',
        component: AdminDashboardComponent,
        title: 'FlowStruct - Admin-Dashboard'
      },
      {
        path: 'customers',
        component: AdminCustomersComponent,
        title: 'FlowStruct - Customers',
      },
      {
        path: 'customers/add',
        pathMatch: 'full',
        component: AddCustomerComponent,
        title: 'FlowStruct - Add Customer',
      },
      {
        path: 'customers/:id',
        component: AdminCustomerDetailComponent,
        title: 'FlowStruct - Customer',
      },
      {
        path: 'customers/:id/edit',
        component: AdminCustomerEditComponent,
        title: 'FlowStruct - Edit Customer',
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
      { path: '**', component: NotFoundComponent },
    ],
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [authGuard, customerActivatedGuard],
    canActivateChild: [authGuard, customerActivatedGuard],
    data: { roles: ['User', 'CustomerAdmin', 'CustomerManager'] },
    title: 'FlowStruct - Dashboard',
    children: [
      {
        path: 'dashboard',
        component: UserDashboardComponent,
      },
      {
        path: 'dashboard/:projectId',
        component: UserDashboardComponent,
      },
      {
        path: 'workgroups',
        component: WorkgroupListComponent,
        title: 'FlowStruct - Workgroups',
        data: { requiredFeature: 'workgroups' }
      },
      {
        path: 'workgroups/:id',
        component: WorkgroupDetailComponent,
        title: 'FlowStruct - Workgroup',
      },
      {
        path: 'my-projects',
        component: MyProjectsComponent,
        title: 'FlowStruct - My projects',
      },
      {
        path: 'diagrams',
        title: 'FlowStruct - Diagrams',
        component: DiagramViewerComponent,
      },
      {
        path: 'diagrams/:id',
        title: 'FlowStruct - Diagrams',
        component: DiagramViewerComponent,
      },
      {
        path: 'organization-users',
        title: 'FlowStruct - Benutzer',
        data: { roles: ['CustomerAdmin'] },
        component: CustomerUsersComponent
      },
      {
        path: 'manage-subscription',
        title: 'FlowStruct - Manage subscription',
        data: { roles: ['CustomerAdmin'] },
        component: ManageSubscriptionComponent,
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
      { path: '**', component: NotFoundComponent },
    ],
  },
  {
    path: 'home',
    title: 'FlowStruct - Get into the flow, structured',
    component: HomeComponent,
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'renew-subscription',
    canActivate: [authGuard],
    component: RenewSubscriptionComponent,
    title: 'FlowStruct - Renew subscription',
  },
  {
    path: 'register',
    component: WizardContainerComponent,
    title: 'FlowStruct - Register',
  },
  {
    path: 'payment-canceled',
    component: PaymentCanceledComponent,
    title: 'FlowStruct - Payment canceled',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./shared/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
    title: 'FlowStruct - Not Found',
  },
];
