import { Component } from '@angular/core';
import { ProjectDashboardComponent } from "../../project/project-dashboard/project-dashboard.component";

@Component({
  selector: 'app-user-dashboard',
  standalone: true,
  imports: [ProjectDashboardComponent],
  templateUrl: './user-dashboard.component.html',
  styleUrl: './user-dashboard.component.scss'
})
export class UserDashboardComponent {

}
