import { Component, input, output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { FormField } from '../../../shared/form/form-field';
import { FormComponent } from '../../../shared/form/form.component';
import { KanbanCardModel } from '../kanban.model';

@Component({
  selector: 'app-add-card',
  standalone: true,
  imports: [DialogComponent, TranslateModule, FormComponent],
  templateUrl: './add-card.component.html',
  styleUrl: './add-card.component.scss'
})
export class AddCardComponent {
  showDialog = input<boolean>(false);

  todoAdded = output<KanbanCardModel>();
  canceled = output<void>();

  fields: FormField[] = [
    {
      name: 'title',
      labelKey: 'diagrams.kanban.dialog.title',
      fieldType: 'text',
      required: true,
    },
    {
      name: 'description',
      labelKey: 'diagrams.kanban.dialog.description',
      fieldType: 'textarea',
      required: false,
    }
  ];

  onAdd(form: FormGroup) {
    const cardModel = form.value as KanbanCardModel;
    cardModel.id = uuid();
    this.todoAdded.emit(cardModel);
  }

  onCancel() {
    this.canceled.emit();
  }
}
