<div class="admin-layout">
  <nav class="admin-layout__menu">
    <div class="admin-layout__menu__logo">
      <h1 class="admin-layout__menu__logo__title">Flowstruct</h1>
    </div>
    <app-admin-menu></app-admin-menu>
  </nav>
  <main class="admin-layout__content">
    <div>
    <app-current-user></app-current-user>
  </div>
    <router-outlet></router-outlet>
  </main>
</div>

