<div class="container">
  <h1 style="text-align: center;">{{ "unauthenticated.register.check-data.title" | translate }}</h1>
  <div class="flex-column-start-center">
  <div class="flex-row-center-start wrap gap-20">
    <div class="flex-column-start-center billing">
      <h2>
        {{ "unauthenticated.register.check-data.chosen-sku" | translate }}
      </h2>
      <app-sku-item
        [sku]="registerStore.skuModel()"
        [displayOnly]="true"
        [freeVersion]="registerStore.testVersionSelected()"
      />
    </div>
    <div class="flex-column-start-center">
      <h2>
        {{ "unauthenticated.register.check-data.customer-data" | translate }}
      </h2>
      <app-data-group [labelKey]="''" [minimal]="true">
        <app-data-point
          [labelKey]="'unauthenticated.register.customer-form.name'"
          [value]="registerStore.addCustomerParam()?.name"
          type="text"
        />
        <app-data-point
          [labelKey]="
            'unauthenticated.register.customer-form.adminEmailDisplay'
          "
          [value]="registerStore.adminEmailOrDefault()"
          type="text"
        />
        <app-data-point
          [labelKey]="'unauthenticated.register.customer-form.tenantId'"
          [value]="registerStore.addCustomerParam()?.tenantId"
          type="text"
        />
      </app-data-group>
    </div>
  </div>
  <div class="cost-calculation">
    <h3>
      {{
        "unauthenticated.register.check-data.prospected-cost" | translate
      }}
    </h3>
    @if (!registerStore.testVersionSelected()) {
    <div class="cost-point">
      {{ "unauthenticated.register.check-data.sku" | translate }}
      <span>{{ registerStore.skuModel()?.price | currency : "EUR" }}</span>
    </div>
    <div class="cost-point">
      {{ "unauthenticated.register.check-data.amountOfUsers" | translate }}
      <span>{{
        registerStore.addCustomerParam()?.givenUserCount | number
      }}</span>
    </div>
    <div class="cost-point">
      {{ "unauthenticated.register.check-data.total-before-tax" | translate }}
      <span>{{
        registerStore.totalBeforeTax() | currency : "EUR"
      }}</span>
    </div>
    <div class="cost-point">
      {{ "unauthenticated.register.check-data.tax" | translate }}
      <span>{{ 0.19 | percent }}</span>
    </div>
    <div class="cost-point">
      {{
        "unauthenticated.register.check-data.total-with-tax" | translate
      }}*
      <span class="total"
        >{{ registerStore.totalCost() | currency : "EUR" }}
        {{
          "unauthenticated.register.check-data.per-month" | translate
        }}</span
      >
    </div>
    <p>*{{ "unauthenticated.register.check-data.hint" | translate }}</p>
    } @else {
    <div class="cost-point">
      <span>{{
        "unauthenticated.register.check-data.free" | translate
      }}</span>
    </div>
    }
  </div>
</div>
  <div class="btn-group center">
    <button class="btn-dark" (click)="registerStore.goBack()">
      {{ "unauthenticated.register.check-data.adjust" | translate }}
    </button>
    @if (loading()) {
      <app-loading-spinner [minimal]="true" />
    }
    @else {
    <button
      class="btn-secondary"
      (click)="
        proceed()
      "
    >
      @if (!registerStore.testVersionSelected()) {
      {{ "unauthenticated.register.check-data.next" | translate }}
      } @else {
      {{ "unauthenticated.register.check-data.finish" | translate }}
      }
    </button>
  }
  </div>
</div>
