import { Injectable } from '@angular/core';
import { PriorityModel } from './priority.model';

@Injectable({
  providedIn: 'root'
})
export class PriorityService {

  constructor() { }

  serializePriorityData(data: PriorityModel): string {
    return JSON.stringify(data);
  }

  deserializePriorityData(data: string): PriorityModel {
    return JSON.parse(data);
  }
}
