<div class="user-menu-navigation" [class.collapsed]="menuCollapsed()">
  <a
    routerLink="/user/dashboard"
    routerLinkActive="active"
    class="user-menu-navigation__item"
    [class.collapsed]="menuCollapsed()"
  >
    <fa-icon [icon]="faChartArea" size="lg"></fa-icon>
    @if (!menuCollapsed()) {
    {{ "userMenu.dashboard" | translate }}
    }
  </a>
  <a
    appFeatureDisplay
    [minimumFeatureSku]="2"
    routerLink="/user/workgroups"
    routerLinkActive="active"
    class="user-menu-navigation__item"
    [class.collapsed]="menuCollapsed()"
  >
    <fa-icon [icon]="faUserGroup" size="lg"></fa-icon>
    @if (!menuCollapsed()) {
    {{ "userMenu.workgroups" | translate }}
    <app-test-version-legend [sku]="2" />
    }
  </a>
  <a
    appFeatureDisplay
    [minimumFeatureSku]="1"
    routerLink="/user/my-projects"
    routerLinkActive="active"
    class="user-menu-navigation__item"
    [class.collapsed]="menuCollapsed()"
  >
    <fa-icon [icon]="faUserLock" size="lg"></fa-icon>
    @if (!menuCollapsed()) {
    {{ "userMenu.myProjects" | translate }}
    <app-test-version-legend [sku]="1" />
    }
  </a>
  @if (currentUserStore.isCustomerAdmin()) {
  <a
    appFeatureDisplay
    [minimumFeatureSku]="1"
    routerLink="/user/organization-users"
    routerLinkActive="active"
    class="user-menu-navigation__item"
    [class.collapsed]="menuCollapsed()"
  >
    <fa-icon [icon]="faUsersGear" size="lg"></fa-icon>
    @if (!menuCollapsed()) {
    {{ "userMenu.organization-users" | translate }}
    <app-test-version-legend [sku]="1" />
    }
  </a>
  <a
    appFeatureDisplay
    [minimumFeatureSku]="0"
    routerLink="/user/manage-subscription"
    routerLinkActive="active"
    class="user-menu-navigation__item"
    [class.collapsed]="menuCollapsed()"
  >
    <fa-icon [icon]="faHandHoldingDollar" size="lg"></fa-icon>
    @if (!menuCollapsed()) {
    {{ "userMenu.manage-subscription" | translate }}
    }
  </a>
  }
</div>
