import { Component, inject, input, output, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { FormField } from '../../../shared/form/form-field';
import { FormComponent } from '../../../shared/form/form.component';
import { CustomerUserRepository } from '../customer-user.repository';

@Component({
  selector: 'app-change-role-dialog',
  standalone: true,
  imports: [DialogComponent, FormComponent],
  templateUrl: './change-role-dialog.component.html',
  styleUrl: './change-role-dialog.component.scss',
})
export class ChangeRoleDialogComponent {
  showDialog = input<boolean>(false);
  user = input<{ userId: string; role: string } | undefined>(undefined);
  submitted = output<void>();
  canceled = output<void>();
  fields = signal<FormField[]>([]);
  loading = signal<boolean>(false);

  customerUserRepository = inject(CustomerUserRepository);

  ngOnChanges() {
    this.initializeFields();
  }

  initializeFields() {
    this.fields.set([
      {
        name: 'role',
        labelKey: 'organization-users.table.role',
        fieldType: 'select',
        required: true,
        defaultValue: this.user()?.role,
        selectOptions: [
          {
            key: 'User',
            value: 'User',
            translateKey: 'organization-users.table.user',
          },
          {
            key: 'CustomerManager',
            value: 'CustomerManager',
            translateKey: 'organization-users.table.customerManager',
          },
        ],
      },
    ]);
  }

  onSubmit($event: FormGroup) {
    this.loading.set(true);
    this.customerUserRepository.updateUserRole(this.user()!.userId, $event.value.role).subscribe({
      next: () => {
        this.loading.set(false);
        this.submitted.emit();
      },
      error: () => {
        this.loading.set(false);
      }
    });
    this.submitted.emit();
  }

  onCancel() {
    this.canceled.emit();
  }
}
