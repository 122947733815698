import { Component, input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { SkuFeatureModel } from '../../sku.model';

@Component({
  selector: 'app-feature-item',
  standalone: true,
  imports: [FontAwesomeModule, TranslateModule],
  templateUrl: './feature-item.component.html',
  styleUrl: './feature-item.component.scss'
})
export class FeatureItemComponent {
  feature = input<SkuFeatureModel>();

  faCheck = faCheck
  faCross = faXmark
}
