<div class="flex-column-center-center">
  <div class="slidecontainer">
    <label>{{ min() }}</label>
    <input
      type="range"
      [min]="min()"
      [max]="max()"
      [(ngModel)]="internalValue"
      (input)="onChange($event)"
      class="slider"
    />

    <label>{{ max() }}</label>
  </div>
  <label class="current-value">{{ internalValue() }}</label>
</div>
