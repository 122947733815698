import { DatePipe } from '@angular/common';
import { Component, EventEmitter, input, Input, OnInit, Output, signal } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { PriorityIssue } from '../priority-issue';
import { PriorityIssueState } from './priority-issue-state';

@Component({
    selector: 'app-priority-issue',
    imports: [FontAwesomeModule, TranslateModule, DatePipe, ConfirmationDialogComponent],
    templateUrl: './priority-issue.component.html',
    styleUrl: './priority-issue.component.scss'
})
export class PriorityIssueComponent implements OnInit {
  faTrash = faTrash;
  faCheck = faCheck;
  @Input({required: true}) issue!: PriorityIssue;
  @Input() displayOnly = false;
  archived = input<boolean>(false);
  @Output() remove = new EventEmitter<void>();
  @Output() removeForever = new EventEmitter<void>();
  @Output() done = new EventEmitter<void>();
  protected priorityLetter!: string;
  protected historyState!: PriorityIssueState;

  showRemoveDialog = signal(false);
  showRemoveForeverDialog = signal(false);

  ngOnInit(): void {
    this.priorityLetter = this.getLetter();
    this.historyState = this.issue.state!;
  }

  getLetter(): string {
    return this.issue.priority.toString();
  }

  triggerRemoveDialog(): void {
    if (this.displayOnly) {
      this.showRemoveForeverDialog.set(true);
    } else {
      this.showRemoveDialog.set(true);
    }
  }

  cancelRemove(): void {
    this.showRemoveDialog.set(false);
    this.showRemoveForeverDialog.set(false);
  }

  markAsDone(): void {
    this.done.emit();
  }
}
