import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CustomerDetailModel } from '../admin-customer-detail/customer-detail.model';
import { CustomerUpdateParam } from '../admin-customer-edit/customer-update-param';
import { UserCountModel } from '../models/user/user-count.model';
import { HttpService } from '../shared/http.service';
import { UserModel } from '../user/user.model';
import { AddCustomerModel } from './add-customer/add-customer.model';
import { CustomerModel } from './customer.model';

const apiUrl = environment.apiConfig.url;

@Injectable({
  providedIn: 'root',
})
export class CustomerRepository {
  constructor(private readonly httpService: HttpService) {}

  getCustomers(): Observable<CustomerModel[]> {
    return this.httpService.get<CustomerModel[]>(`${apiUrl}/customer/all`);
  }

  addCustomer(model: AddCustomerModel): Observable<any> {
    return this.httpService.post(`${apiUrl}/customer/add`, model);
  }

  deleteCustomer(id: string): Observable<any> {
    return this.httpService.delete(`${apiUrl}/customer/delete/${id}`);
  }

  getById(id: string): Observable<CustomerDetailModel> {
    return this.httpService.get<CustomerDetailModel>(`${apiUrl}/customer/${id}`);
  }

  updateCustomer(id: string, param: CustomerUpdateParam): Observable<any> {
    return this.httpService.put(`${apiUrl}/customer/update/${id}`, param);
  }

  toggleCustomerActive(id: string): Observable<any> {
    return this.httpService.put(`${apiUrl}/customer/toggle-active/${id}`, null);
  }

  getCustomerUsersById(id: string): Observable<UserModel[]> {
    return this.httpService.get(`${apiUrl}/customer/users-of-customer/${id}`);
  }

  getUserCountOfCustomer(id: string): Observable<UserCountModel> {
    return this.httpService.get(`${apiUrl}/customer/user-count-of-customer/${id}`);
  }
}
