<h1>{{ "projects.myProjects.title" | translate }}</h1>
<div class="checkboxGroup">
  <label for="onlyPrivate">{{
    "projects.myProjects.onlyPrivateButton" | translate
  }}</label>
  <input
    id="onlyPrivate"
    type="checkbox"
    [(ngModel)]="includePrivate"
    (change)="onCheckedChange()"
  />
</div>
<div class="btn-group">
  <button class="btn-success" (click)="onAddPrivateProjectButtonClick()">
    <fa-icon [icon]="faPlus" size="lg"></fa-icon>
    {{ "projects.myProjects.addPrivateProjectButton" | translate }}
  </button>
  <button
    appFeatureDisplay
    [minimumFeatureSku]="2"
    class="btn-success"
    (click)="onAddProjectButtonClick()"
  >
    <fa-icon [icon]="faPlus" size="lg"></fa-icon>
    {{ "projects.myProjects.addProjectButton" | translate }}
  </button>
  <app-test-version-legend
    [sku]="1"
    [isDiagramButton]="true"
  ></app-test-version-legend>
</div>
@if (loadingProjects) {
<app-loading-spinner />
} @else {
<app-data-table
  [columns]="columns"
  [data]="rows"
  [onSelectRow]="onDetailClick"
/>
}
<app-add-project
  [showDialog]="showAddPrivateProjectDialog"
  [private]="true"
  (projectAdded)="onPrivateProjectAdded()"
  (canceled)="toggleAddPrivateDialog()"
/>
<app-add-with-workgroup
  appFeatureDisplay
  [minimumFeatureSku]="2"
  [showDialog]="showAddProjectDialog"
  (projectAdded)="onProjectAdded()"
  (canceled)="toggleAddDialog()"
/>
