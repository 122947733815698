import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MultiselectService {

  constructor() { }

  public filterData(data: any[], searchProp: string, searchTerm?: string): any[] {
    if  (!searchTerm) {
      return data;
    }
    return data.filter((item) => {
      return item[searchProp].toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  public filterThatsAlreadyPresent(data: any[], selectedItems: any[], searchProp: string): any[] {
    return data.filter((x) => !selectedItems.some(y => y[searchProp] === x[searchProp]));
  }
}
