@if (!freeVersion()) {
<h2>{{ sku()!.name }}</h2>
<p class="item-description">
  {{ sku()!.descriptionKey | translate }}
</p>
<div class="features">
  <h3>{{ 'unauthenticated.register.features.title' | translate }}</h3>
  @for (feature of sku()!.features; track $index) {
    <app-feature-item [feature]="feature"></app-feature-item>
  }
</div>
<div class="item-price-container">
  <p class="item-price">{{ sku()!.price | currency : "EUR" : "symbol" }}</p>
  <p>{{ "unauthenticated.register.sku-selection-per-user" | translate }}</p>
  <p class="tax-hint">{{ "unauthenticated.register.excluding-tax" | translate }}</p>
</div>
} @else {
  <div class="free-version">
    <h2>{{ 'unauthenticated.register.free-version' | translate }}</h2>
  </div>
}
