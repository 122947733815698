import { Component, inject, input, output, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUserStore } from '../../auth/current-user/current-user.store';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { FormField } from '../../shared/form/form-field';
import { FormComponent } from '../../shared/form/form.component';
import { WorkgroupModel } from '../../workgroups/workgroup.model';
import { WorkgroupRepository } from '../../workgroups/workgroup.repository';
import { ProjectRepository } from '../project.repository';

@Component({
  selector: 'app-add-with-workgroup',
  imports: [DialogComponent, FormComponent, TranslateModule, RouterModule],
  templateUrl: './add-with-workgroup.component.html',
  styleUrl: './add-with-workgroup.component.scss',
})
export class AddWithWorkgroupComponent {
  destroy$ = new Subject<void>();
  fields = signal<FormField[]>([]);
  workgroups = signal<WorkgroupModel[]>([]);
  showCreateWorkgroupAlert = signal(false);

  workgroupRepo = inject(WorkgroupRepository);
  projectRepo = inject(ProjectRepository);
  currentUserStore = inject(CurrentUserStore);

  readonly showDialog = input(false);
  canceled = output();
  loading = output<boolean>();
  projectAdded = output();

  ngOnInit() {
    this.setupFields();
  }

  private setupFields() {
    this.workgroupRepo
      .getWorkgroups()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (workgroups) => {
          if (workgroups.workgroups.length === 0) {
            this.showCreateWorkgroupAlert.set(true);
          } else {
            this.fields.set([
              {
                name: 'projectName',
                fieldType: 'text',
                required: true,
                validators: [
                  Validators.required,
                  Validators.minLength(3),
                  Validators.maxLength(100),
                ],
                labelKey: 'projects.add.name',
              },
              {
                name: 'workgroup',
                fieldType: 'select',
                required: true,
                labelKey: 'projects.add.workgroup',
                defaultValue: false,
                validators: [Validators.required],
                selectOptions: workgroups.workgroups.map((w) => ({
                  value: w.id,
                  label: w.name,
                  key: w.name,
                })),
              },
            ]);
          }
        },
      });
  }

  addProject(form: FormGroup): void {
    this.loading.emit(true);
    this.addWorkgroupProject(form);
  }

  cancel(): void {
    this.canceled.emit();
  }

  private addWorkgroupProject(form: FormGroup): void {
    this.projectRepo
      .addProjectToWorkgroup({
        workgroupId: form.get('workgroup')?.value,
        projectName: form.get('projectName')?.value,
        isPrivate: false,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.loading.emit(false);
          this.projectAdded.emit();
        },
        error: () => {
          this.loading.emit(false);
        },
      });
  }
}
