<h1>{{ titleKey | translate }}</h1>
<form [formGroup]="formGroup">
  @for (field of fields; track field.name) { @switch(field.fieldType) {
  @case('text') {
  <label
    >{{ field.labelKey | translate }}
    @if(field.required) {
    <span class="req">*</span>
    }
  </label>
  <input
    type="text"
    [formControlName]="field.name"
    [required]="field.required"
    [readonly]="field.disabled ?? false"
  />
  } @case('email') {
  <label
    >{{ field.labelKey | translate }}
    @if(field.required) {
    <span class="req">*</span>
    }</label
  >
  <input
    type="email"
    [formControlName]="field.name"
    [required]="field.required"
    [readonly]="field.disabled ?? false"
  />
  } @case ('password') {
  <label
    >{{ field.labelKey | translate }}
    @if(field.required) {
    <span class="req">*</span>
    }</label
  >
  } @case('textarea') {
  <label
    >{{ field.labelKey | translate }}
    @if(field.required) {
    <span class="req">*</span>
    }</label
  >
  <textarea
    [formControlName]="field.name"
    [required]="field.required"
    [readonly]="field.disabled ?? false"
  ></textarea>
  } @case('select') {
  <label
    >{{ field.labelKey | translate }}
    @if(field.required) {
    <span class="req">*</span>
    }</label
  >
  <select
    [formControlName]="field.name"
    [required]="field.required"
    [disabled]="field.disabled ?? false"
  >
    @for (option of field.selectOptions; track option.value) {
    <option [value]="option.value" [selected]="field.defaultValue === option.value ? 'selected' : ''">
      {{ option.translateKey ?? option.key | translate }}
    </option>
    }
  </select>
  } @case('checkbox') {
  <label>
    <input
      type="checkbox"
      [formControlName]="field.name"
      [readonly]="field.disabled ?? false"
    />
    {{ field.labelKey | translate }}
    @if(field.required) {
    <span class="req">*</span>
    }
  </label>

  } @case('radio') {
  <label
    >{{ field.labelKey | translate }}
    @if(field.required) {
    <span class="req">*</span>
    }</label
  >
  @for (option of field.selectOptions; track option.value) {
  <label>
    <input
      type="radio"
      [required]="field.required"
      [formControlName]="field.name"
      [value]="option.value"
      [readonly]="field.disabled ?? false"
    />
    {{ option.translateKey ?? option.key | translate }}
  </label>

  } } @case('date') {
  <label
    >{{ field.labelKey | translate }}
    @if(field.required) {
    <span class="req">*</span>
    }</label
  >
  <input
    type="date"
    [formControlName]="field.name"
    [required]="field.required"
    [readonly]="field.disabled ?? false"
  />
  } @case('number') {
  <label
    >{{ field.labelKey | translate }}
    @if(field.required) {
    <span class="req">*</span>
    }</label
  >
  <input
    type="number"
    [formControlName]="field.name"
    [required]="field.required"
    [min]="field.minValue ?? null"
    [max]="field.maxValue ?? null"
    [readonly]="field.disabled ?? false"
  />
  } @case('multi-select') {
  <label
    >{{ field.labelKey | translate }}
    @if(field.required) {
    <span class="req">*</span>
    }
  </label>
  @if (field.selectOptions) {
  <div style="width: fit-content">
    <app-multiselect
      [selectionItems]="field.selectOptions!"
      [selectedItems]="field.defaultSelectedMultiSelectItems ?? []"
      [displayProp]="field.displayProp!"
      [searchProp]="field.displayProp!"
      (selectedItemsChanged)="onSelectedItemsChanged($event, field)"
    />
  </div>
  <input type="hidden" [formControlName]="field.name" />
  } } } }
  <div class="btn-group">
    <button type="button" [class]="cancelClass()" (click)="onCancel()">
      {{ cancelTextKey() | translate }}
    </button>
    <button
      [disabled]="!formGroup.valid"
      type="button"
      [class]="submitClass()"
      (click)="onSubmit()"
      [disabled]="!formGroup.valid"
    >
      {{ submitTextKey() | translate }}
    </button>
  </div>
</form>
