import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, input, OnInit, output, signal } from '@angular/core';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArrowLeft,
  faArrowsUpDownLeftRight,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { DiagramRepository } from '../diagram.repository';
import { DiagramModel } from '../models/diagram.model';
import { UpdateDiagramModel } from '../models/update-diagram.model';
import { AddCardComponent } from './add-card/add-card.component';
import { AddLaneComponent } from './add-lane/add-lane.component';
import { KanbanCardModel, KanbanLaneModel, KanbanModel } from './kanban.model';

@Component({
  selector: 'app-kanban',
  imports: [
    TranslateModule,
    FontAwesomeModule,
    AddCardComponent,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    CdkDragHandle,
    AddLaneComponent,
    ConfirmationDialogComponent,
  ],
  templateUrl: './kanban.component.html',
  styleUrl: './kanban.component.scss',
})
export class KanbanComponent implements OnInit {
  destroy$ = new Subject<void>();
  faArrowLeft = faArrowLeft;
  faPlus = faPlus;
  faTrash = faTrash;
  faMove = faArrowsUpDownLeftRight;

  diagram = input<DiagramModel | undefined>(undefined);
  changed = output<UpdateDiagramModel>();

  kanbanModel = signal<KanbanModel | undefined>(undefined);
  showAddCard = signal<boolean>(false);
  showAddLane = signal<boolean>(false);
  maxLaneCount = 10;
  showDeleteLaneConfirmationDialog = signal<boolean>(false);
  showDeleteCardConfirmationDialog = signal<boolean>(false);
  selectedLaneIndex = signal<number | undefined>(undefined);
  selectedCardIndex = signal<{ laneId: string; cardId: string } | undefined>(
    undefined
  );

  constructor(
    private readonly diagramRepository: DiagramRepository,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    const diagramData = JSON.parse(this.diagram()!.diagramJson);
    this.kanbanModel.set(diagramData);
  }

  drop(event: CdkDragDrop<KanbanCardModel[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.updateDiagram();
  }

  dropLane(event: CdkDragDrop<KanbanLaneModel[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    this.updateDiagram();
  }

  addLane() {
    this.showAddLane.set(true);
  }

  deleteLane(laneIndex: number) {
    this.selectedLaneIndex.set(laneIndex);
    this.showDeleteLaneConfirmationDialog.set(true);
  }

  onDeleteLaneConfirm() {
    this.showDeleteLaneConfirmationDialog.set(false);
    const kanbanModel = this.kanbanModel();
    if (kanbanModel) {
      kanbanModel.lanes.splice(this.selectedLaneIndex()!, 1);
      this.kanbanModel.set(kanbanModel);
      this.updateDiagram();
    }
  }

  onDeleteLaneCancel() {
    this.showDeleteLaneConfirmationDialog.set(false);
    this.selectedLaneIndex.set(undefined);
  }

  onSubmitLane(lane: string) {
    this.showAddLane.set(false);
    const kanbanModel = this.kanbanModel();
    if (kanbanModel) {
      kanbanModel.lanes.push({ id: uuid(), title: lane, cards: [] });
      this.kanbanModel.set(kanbanModel);
      this.updateDiagram();
    }
  }

  onCancelLane() {
    this.showAddLane.set(false);
  }

  addCard() {
    this.showAddCard.set(true);
  }

  backToDiagrams(): void {
    if (!this.diagram()?.projectId !== null) {
      this.router.navigate([
        'user/dashboard/',
        this.diagram()?.projectId ?? '',
      ]);
    } else {
      this.router.navigate(['user/dashboard/private']);
    }
  }

  deleteCard(laneId: string, cardId: string) {
    this.selectedCardIndex.set({
      laneId,
      cardId,
    });
    this.showDeleteCardConfirmationDialog.set(true);
  }

  onDeleteCardConfirm() {
    this.showDeleteCardConfirmationDialog.set(false);
    const kanbanModel = this.kanbanModel();
    if (kanbanModel) {
      const lane = kanbanModel.lanes.find(
        (l) => l.id === this.selectedCardIndex()?.laneId
      );
      if (lane) {
        lane.cards = lane.cards.filter(
          (c) => c.id !== this.selectedCardIndex()?.cardId
        );
        this.kanbanModel.set(kanbanModel);
        this.updateDiagram();
      }
    }
    this.selectedCardIndex.set(undefined);
  }

  onDeleteCardCancel() {
    this.showDeleteCardConfirmationDialog.set(false);
    this.selectedCardIndex.set(undefined);
  }

  updateDiagram() {
    const diagram = this.diagram();
    if (diagram) {
      const diagramData = { type: 'kanban', ...this.kanbanModel() };
      diagram.diagramJson = JSON.stringify(diagramData);
      this.changed.emit(diagram);
    }
  }

  onSubmitCard(card: KanbanCardModel) {
    this.showAddCard.set(false);
    const kanbanModel = this.kanbanModel();
    if (kanbanModel) {
      const lane = kanbanModel.lanes[0];
      lane.cards.push(card);
      this.kanbanModel.set(kanbanModel);
      this.updateDiagram();
    }
  }

  onCancelCard() {
    this.showAddCard.set(false);
  }

  allowDropPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    return drag.data === undefined;
  };
}
