import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpService } from '../../../shared/http.service';
import { DeclineReason } from './decline-reason.enum';

const apiConfig = environment.apiConfig;

@Injectable({ providedIn: 'root' })
export class PaymentCanceledRepository {
  constructor(private readonly httpService: HttpService) {}

  provideFeedback(reason: DeclineReason, feedback: string, customerName: string | undefined): Observable<void> {
    return this.httpService.post(apiConfig.url + '/feedback/for-register', { declineReason: reason, feedback, companyNameOrName: customerName });
  }
}
