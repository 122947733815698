<button
  class="language-button"
  [style.background-image]="'url(images/' + currentlySelected() + '.png)'"
  (click)="toggleDropdown()"
></button>
@if (showDropdown()) {
<div class="languages-container">
  <button
    class="language-button"
    [class.selected]="currentlySelected() === 'de'"
    [style.background-image]="'url(images/de.png)'"
    (click)="setLanguage('de')"
  ></button>
  <button
    class="language-button"
    [class.selected]="currentlySelected() === 'en'"
    [style.background-image]="'url(images/en.png)'"
    (click)="setLanguage('en')"
  ></button>
</div>
}
