<h1>{{ "manage-subscription.title" | translate }}</h1>
<div class="current-subscription">
  <h2>{{ "manage-subscription.current-subscription" | translate }}</h2>
  <span
    class="sub"
    [class.basic]="featureStore.isBasic()"
    [class.startup]="featureStore.isStartup()"
    [class.pro]="featureStore.isPro()"
  >
    @if (featureStore.isBasic()) { BASIC } @else if (featureStore.isStartup()) {
    STARTUP } @else if (featureStore.isPro()) { PRO }
  </span>
  @if (store.loading()) {
  <app-loading-spinner [minimal]="true" />
  } @else if (store.subscriptionCanceled()) {
  <span
    >{{ "manage-subscription.canceled" | translate }}
    {{ store.periodEnd() | date : "dd.MM.yyyy HH:mm" }}</span
  >
  } @else {
  <button class="btn-danger" (click)="toggleCancelDialog()">
    {{ "manage-subscription.cancel" | translate }}
  </button>
  }
</div>

<app-dialog
  [dialogTitle]="'manage-subscription.cancel-confirm-title' | translate"
  [showDialog]="store.showCancelDialog()"
>
  <p>{{ "manage-subscription.cancel-confirm" | translate }}</p>
  <div class="btn-group">
  <button class="btn-secondary" (click)="cancelSubscription()">{{ "manage-subscription.cancel-confirm-button" | translate }}</button>
  <button class="btn-light" (click)="toggleCancelDialog()">{{ "generic.cancel" | translate }}</button>
</div>
</app-dialog>
