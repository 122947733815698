import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminMenuComponent } from '../admin-menu/admin-menu.component';
import { CurrentUserComponent } from "../auth/current-user/current-user.component";

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [RouterModule, AdminMenuComponent, CurrentUserComponent],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
})
export class AdminComponent {}
