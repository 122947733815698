<app-dialog [showDialog]="show()">
  <h2>{{ titleKey() | translate }}</h2>
  <p>
    @if (message()) {
    {{ message() }}
    } @else {
    {{ messageKey() | translate }}
    }
  </p>
  <div class="btn-group">
    <button class="btn-secondary" (click)="onConfirm()">
      {{ confirmKey() | translate }}
    </button>
    <button class="btn-dark" (click)="onCancel()">
      {{ cancelKey() | translate }}
    </button>
  </div>
</app-dialog>
