@if (type()) { @switch (type()) { @case('priority') {
<div class="priority-mock">
  <div id="B-Area" class="priority-mock-area"></div>
  <div id="A-Area" class="priority-mock-area"></div>
  <div id="D-Area" class="priority-mock-area"></div>
  <div id="C-Area" class="priority-mock-area"></div>
</div>
} @case('kanban') {
<div class="kanban-mock">
  <div class="kanban-lane">
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
  </div>
  <div class="kanban-lane">
    <div class="item"></div>
    <div class="item"></div>
  </div>
  <div class="kanban-lane">
    <div class="item"></div>
  </div>
</div>
} @case('lean-canvas') {
<div class="lean-canvas-mock">
  <div class="item-a"></div>
  <div class="item-a"></div>
  <div class="item-a"></div>
  <div class="item-a"></div>
  <div class="item-b"></div>
  <div class="item-b"></div>
  <div class="test-version-legend">
    <app-test-version-legend [sku]="1" [height]="20"></app-test-version-legend>
  </div>
</div>
} @case('risk-matrix') {
<div class="risk-matrix-mock">
</div>
} @default {
<div class="unknown">?</div>
} } } @else {
<div class="unknown">?</div>
}
