<div class="multiselect-container">
  <div class="multiselect-box" (click)="onClickContainer()">
    @if (store.selectedItems().length === 0) {
    <span class="multiselect-box__placeholder">{{
      placeholder | translate
    }}</span>
    } @else { @for (item of store.selectedItems(); track $index) {
    <div class="multiselect-box__item" (click)="onRemoveItem($event, item)">
      <span>
        @if (item.locked) {
        <fa-icon size="xs" [icon]="faLock" />
        }
        {{ item[displayProp()] }}
      </span>
      @if (!item.locked) {
      <span class="multiselect-box__item__remove">x</span>
      }
    </div>
    } }
  </div>
  @if (showDropdown()) {
  <div class="multiselect-search">
    <fa-icon class="search-icon" [icon]="faSearch"></fa-icon>
    <input type="search" [(ngModel)]="searchTerm" (input)="onSearch()" />
    <div style="position: relative">
      <div class="multiselect-available-items">
        @for (item of store.searchedItems(); track $index) {
        <div
          class="multiselect-available-items__item"
          (click)="onSelectItem(item)"
        >
          {{ item[displayProp()] }}
        </div>
        }
      </div>
    </div>
  </div>
  }
</div>
