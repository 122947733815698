<div class="register-container">
  <h1 class="register-title">
    {{ "unauthenticated.register.sku-selection-title" | translate }}
  </h1>
  <section class="register-skus">
    @for (sku of skuModels; track $index) {
    <app-sku-item
      [class.selected]="sku.sku === registerStore.selectedSku()"
      [sku]="sku"
      (click)="registerStore.selectSku(sku)"
    ></app-sku-item>
    }
  </section>
  <section class="hint">
    <p>{{ "unauthenticated.register.hint" | translate }}</p>
  </section>
</div>
<section class="buttons">
  <div class="trial">
    <button class="btn-dark" (click)="selectTestVersion()">
      <fa-icon size="xl" [icon]="faInfoCircle" />
      {{ "unauthenticated.register.buttons.free-plan" | translate }}
    </button>
    <p class="button-hint">
      *{{ "unauthenticated.register.buttons.free-plan-hover" | translate }}
    </p>
  </div>
  <button class="btn-secondary" (click)="registerStore.proceedToCustomerStep()">
    {{ "unauthenticated.register.buttons.next" | translate }}
  </button>
</section>
