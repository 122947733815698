import { Component, OnDestroy, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBug,
  faCircleXmark,
  faMoneyCheck,
  faTowerCell,
  faTriangleExclamation,
  faUserLock,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ErrorService, ErrorType } from './error.service';
import { HandledError } from './handled-error';

const maxDismissCount = 3;

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [FontAwesomeModule, TranslateModule],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
})
export class ErrorComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();

  faTriangle = faTriangleExclamation;
  faUserLock = faUserLock;
  faMoneyCheck = faMoneyCheck;
  faTowerCell = faTowerCell;
  faBug = faBug;
  faCircleXmark = faCircleXmark;

  currentIcon?: IconDefinition;

  errors: { error: HandledError; icon: IconDefinition }[] = [];
  dismissed = false;
  dismissCount = 0;

  protected get hasError(): boolean {
    return this.errors.length > 0;
  }

  constructor(private readonly errorService: ErrorService) {}

  ngOnInit(): void {
    this.errorService.errorsChanged$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((errors) => {
        this.errors = errors.map((error) => ({
          error,
          icon: this.getIconBasedOnErrorType(error.errorType),
        }));
        if (this.hasError) {
          this.dismissed = false;
        }
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getIconBasedOnErrorType(type: ErrorType | undefined): IconDefinition {
    if (!type) {
      return this.faBug;
    }

    let icon;
    switch (type) {
      case 'input':
        icon = this.faTriangle;
        break;
      case 'connection':
        icon = this.faTowerCell;
        break;
      case 'authorization':
        icon = this.faUserLock;
        break;
      case 'subscription':
        icon = this.faMoneyCheck;
        break;
      case 'not-found':
        icon = this.faCircleXmark;
        break;
      default:
        icon = this.faBug;
        break;
    }
    return icon;
  }

  toggleDismissed(): void {
    if (!this.dismissed) {
      this.dismissCount++;
      if (this.dismissCount >= maxDismissCount) {
        this.dismissCount = 0;
        this.errorService.clearError();
        return;
      }
    }
    this.dismissed = !this.dismissed;
  }
}
