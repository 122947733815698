import { Component, input, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { DiagramRepository } from '../diagram.repository';
import { LastEditedComponent } from '../last-edited/last-edited.component';
import { DiagramModel } from '../models/diagram.model';
import { UpdateDiagramModel } from '../models/update-diagram.model';
import { LeanCanvasModel } from './lean-canvas.model';

@Component({
  selector: 'app-lean-canvas',
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    LoadingSpinnerComponent,
    LastEditedComponent
  ],
  templateUrl: './lean-canvas.component.html',
  styleUrl: './lean-canvas.component.scss',
})
export class LeanCanvasComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  diagramId = input<string | undefined>(undefined);
  formGroup: FormGroup | undefined;
  diagramJson$: Subject<string | undefined> = new Subject<string | undefined>();
  faArrowLeft = faArrowLeft;
  loading = signal<boolean>(false);

  diagram = signal<DiagramModel | undefined>(undefined);

  constructor(
    private readonly diagramRepository: DiagramRepository,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.loadDiagram();
    this.diagramJson$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe({
        next: (diagramJson) => {
          if (diagramJson) {
            const updateDiagram: UpdateDiagramModel = {
              id: this.diagramId()!,
              diagramJson: diagramJson,
              name: this.diagram()!.name,
            };
            this.diagramRepository
              .update(updateDiagram)
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: () => {
                  this.loadDiagram();
                },
              });
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadDiagram(): void {
    this.loading.set(true);
    this.diagramRepository
      .get(this.diagramId()!)
      .pipe(takeUntil(this.destroy$))
      .subscribe((diagram) => {
        this.initalizeForm(diagram);
        this.diagram.set(diagram);
        this.loading.set(false);
      });
  }

  initalizeForm(diagram: DiagramModel): void {
    const data: LeanCanvasModel = JSON.parse(diagram.diagramJson);

    this.formGroup = new FormGroup({
      problem: new FormControl(data.problem),
      alternatives: new FormControl(data.alternatives),
      solution: new FormControl(data.solution),
      metrics: new FormControl(data.metrics),
      uniqueValueProposition: new FormControl(data.uniqueValueProposition),
      unfairAdvantage: new FormControl(data.unfairAdvantage),
      customerSegments: new FormControl(data.customerSegments),
      channels: new FormControl(data.channels),
      costStructure: new FormControl(data.costStructure),
      revenueStreams: new FormControl(data.revenueStreams),
      highLevelConcept: new FormControl(data.highLevelConcept),
      earlyAdopters: new FormControl(data.earlyAdopters),
    });
  }

  onInputChanged(): void {
    this.diagramJson$.next(
      JSON.stringify({ ...this.formGroup?.value, type: 'lean-canvas' })
    );
  }

  backToDiagrams(): void {
    if (!this.diagram()?.projectId !== null) {
      this.router.navigate([
        'user/dashboard/',
        this.diagram()?.projectId ?? '',
      ]);
    } else {
      this.router.navigate(['user/dashboard/private']);
    }
  }
}
