import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArrowLeft,
  faEdit,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUserStore } from '../../auth/current-user/current-user.store';
import { AddProjectComponent } from '../../project/add-project/add-project.component';
import { ProjectModel } from '../../project/project.model';
import { ProjectRepository } from '../../project/project.repository';
import { DataTableColumn } from '../../shared/data-table/data-table-column';
import { DataTableComponent } from '../../shared/data-table/data-table.component';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { FormField } from '../../shared/form/form-field';
import { FormComponent } from '../../shared/form/form.component';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { TestVersionLegendComponent } from '../../shared/test-version-legend/test-version-legend.component';
import { AddUserWorkgroupComponent } from '../add-user-workgroup/add-user-workgroup.component';
import { WorkgroupModel } from '../workgroup.model';
import { WorkgroupRepository } from '../workgroup.repository';

@Component({
  selector: 'app-workgroup-detail',
  standalone: true,
  imports: [
    FontAwesomeModule,
    AddProjectComponent,
    TranslateModule,
    LoadingSpinnerComponent,
    DataTableComponent,
    AddUserWorkgroupComponent,
    DialogComponent,
    FormComponent,
    TestVersionLegendComponent
  ],
  templateUrl: './workgroup-detail.component.html',
  styleUrl: './workgroup-detail.component.scss',
})
export class WorkgroupDetailComponent implements OnInit, OnDestroy {
  currentUserStore = inject(CurrentUserStore);
  private destroy$ = new Subject<void>();

  faEdit = faEdit;
  faTrash = faTrash;
  faArrowLeft = faArrowLeft;
  faPlus = faPlus;

  rows: any[] = [];
  loadingDetail: boolean = false;
  loadingProjects: boolean = false;

  workgroup!: WorkgroupModel;
  showAddProjectDialog: boolean = false;
  showEditWorkgroupDialog = signal(false);
  editWorkgroupFields = signal<FormField[] | undefined>(undefined);

  projects: ProjectModel[] = [];
  columns: DataTableColumn[] = [
    {
      prop: 'name',
      name: 'Name',
      translateKey: 'projects.table.name',
      type: 'text',
    },
    {
      prop: 'ownedBy',
      name: 'Owned by',
      translateKey: 'projects.table.owner',
      type: 'text',
    },
    {
      prop: 'createdAt',
      name: 'Created at',
      translateKey: 'projects.table.createdAt',
      type: 'datetime',
    },
    {
      prop: 'updatedAt',
      name: 'Updated at',
      translateKey: 'projects.table.updatedAt',
      type: 'date',
    },
  ];

  selectRow = (row: ProjectModel) => {
    this.router.navigate(['/user/dashboard/' + row.id]);
  };

  constructor(
    private readonly workgroupRepository: WorkgroupRepository,
    private readonly activatedRoute: ActivatedRoute,
    private readonly projectRepository: ProjectRepository,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadWorkgroupDetail();
  }

  loadWorkgroupDetail(): void {
    this.loadingDetail = true;
    this.workgroupRepository
      .getById(this.activatedRoute.snapshot.paramMap.get('id')!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (workgroup) => {
          this.workgroup = workgroup;
          this.loadingDetail = false;
          this.loadProjectsForWorkgroup();
          this.editWorkgroupFields.set([
            {
              fieldType: 'text',
              name: 'name',
              labelKey: 'workgroups.detail.edit.name',
              required: true,
              defaultValue: workgroup.name,
            },
          ]);
        },
        error: () => {
          this.loadingDetail = false;
        },
      });
  }

  loadProjectsForWorkgroup(): void {
    this.loadingProjects = true;
    this.projectRepository
      .getProjects(this.workgroup.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (projects) => {
          this.rows = projects.map((project) => {
            return {
              ...project,
              ownedBy: project.owner,
              createdAt: project.createdAt,
              updatedAt: project.updatedAt,
            };
          });
          this.projects = projects;
          this.loadingProjects = false;
        },
        error: () => {
          this.loadingProjects = false;
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  deleteWorkgroup(): void {
    this.translateService
      .get('workgroups.detail.delete.confirm')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (message) => {
          if (confirm(message)) {
            this.workgroupRepository
              .deleteWorkgroup(this.workgroup.id)
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: () => {
                  this.router.navigate(['/user/workgroups']);
                },
              });
          }
        },
      });
  }

  editWorkgroup(): void {
    this.showEditWorkgroupDialog.set(true);
  }

  onEditSave(form: FormGroup): void {
    this.workgroupRepository
      .updateWorkgroup(this.workgroup.id, form.get('name')!.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.showEditWorkgroupDialog.set(false);
          this.loadWorkgroupDetail();
        },
      });
  }

  onEditCancel(): void {
    this.showEditWorkgroupDialog.set(false);
  }

  backToList() {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  toggleAddProjectDialog() {
    this.showAddProjectDialog = !this.showAddProjectDialog;
  }

  onProjectAdded() {
    this.toggleAddProjectDialog();
    this.loadProjectsForWorkgroup();
  }
}
