import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerDetailModel } from '../admin-customer-detail/customer-detail.model';
import { CustomerRepository } from '../admin-customers/customer.repository';
import { FormField } from '../shared/form/form-field';
import { FormComponent } from "../shared/form/form.component";
import { LoadingSpinnerComponent } from '../shared/loading-spinner/loading-spinner.component';
import { CustomerUpdateParam } from './customer-update-param';

@Component({
  selector: 'app-admin-customer-edit',
  standalone: true,
  imports: [TranslateModule, RouterModule, FormComponent, LoadingSpinnerComponent],
  templateUrl: './admin-customer-edit.component.html',
  styleUrl: './admin-customer-edit.component.scss',
})
export class AdminCustomerEditComponent implements OnInit {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly customerService: CustomerRepository
  ) {}

  fields!: FormField[];

  customerId!: string;
  loadedCustomer!: CustomerDetailModel;
  loading: boolean = false;

  ngOnInit(): void {
    this.customerId = this.activatedRoute.snapshot.params['id'];
    this.loadCustomer();
  }

  loadCustomer(): void {
    this.loading = true;
    this.customerService.getById(this.customerId).subscribe({
      next: (customer) => {
        this.loadedCustomer = customer;
        this.intializeFields();
        this.loading = false;
      },
      error: () => {},
    });
  }

  intializeFields(): void {
    this.fields = [
      {
        name: 'name',
        labelKey: 'admin.customer.addForm.name',
        fieldType: 'text',
        required: true,
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(250),
        ],
        defaultValue: this.loadedCustomer.name
      },
      {
        name: 'vatId',
        labelKey: 'admin.customer.addForm.vatId',
        fieldType: 'text',
        required: false,
        validators: [Validators.maxLength(20)],
        defaultValue: this.loadedCustomer.vatId
      },
      {
        name: 'givenUserCount',
        labelKey: 'admin.customer.addForm.givenUserCount',
        fieldType: 'number',
        required: true,
        minValue: 1,
        maxValue: 1000,
        validators: [Validators.required, Validators.min(1), Validators.max(1000)],
        defaultValue: this.loadedCustomer.givenUserCount
      },
      {
        name: 'sku',
        labelKey: 'admin.customer.addForm.sku',
        fieldType: 'radio',
        required: true,
        validators: [Validators.required],
        selectOptions: [
          { value: 0, translateKey: 'sku.0', key: 'sku1' },
          { value: 1, translateKey: 'sku.1', key: 'sku2' },
          { value: 2, translateKey: 'sku.2', key: 'sku3' },
          { value: 3, translateKey: 'sku.3', key: 'sku4' },
        ],
        defaultValue: this.loadedCustomer.sku
      },
      {
        name: 'customerAdminEmail',
        labelKey: 'admin.customer.addForm.customerAdminEmail',
        fieldType: 'email',
        required: false,
        validators: [Validators.email, Validators.required, Validators.maxLength(250), Validators.minLength(3)],
        defaultValue: this.loadedCustomer.customerAdminEmail
      },
    ]
  }

  save(form: FormGroup): void {
    this.loading = true;
    const customerUpdateParam: CustomerUpdateParam = {
      name: form.get('name')!.value,
      vatId: form.get('vatId')!.value,
      givenUserCount: form.get('givenUserCount')!.value,
      sku: form.get('sku')!.value,
      customerAdminEmail: form.get('customerAdminEmail')!.value,
    };

    this.customerService.updateCustomer(this.customerId, customerUpdateParam).subscribe({
      next: () => {
        this.router.navigate(['admin', 'customers', this.customerId]);
        this.loading = true;
      },
      error: () => {},
    });
  }

  cancel(): void {
    this.router.navigate(['admin', 'customers', this.customerId]);
  }
}
