<div class="background">
  <div class="preview-images">
    <!-- <img
      src="images/workgroupreview.jpg"
      alt="A picture showing the workgroup"
    />
    <img src="images/diagrampreview.jpg" alt="A diagram of flowstruct" />
    <img
      src="images/dialogpreview.jpg"
      alt="A dialog showing the adding functionality"
    /> -->
  </div>
  <div class="main-container">
    <div style="margin-bottom: 80px;">
      <app-logo [width]="'600px'" />
    </div>
    @if(!isLoggedIn()) {
    <div class="auth-button-group">
      <a class="login-button" (click)="login()">{{
        "home.login" | translate
      }}</a>
      <a class="register-button" routerLink="/register">{{
        "home.register" | translate
      }}</a>
      <app-language-switch />
    </div>
    } @else {
    <span class="redirect-message">{{
      "home.redirect-message" | translate
    }}</span>
    }
  </div>
</div>
