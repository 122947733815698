<main>
  <app-language-switch />
  <div class="canceled-container">
    <app-logo />
    @if (!showThankYou()) {
    <h1>{{ "unauthenticated.register.payment-canceled.title" | translate }}</h1>
    <p
      [innerHTML]="
        'unauthenticated.register.payment-canceled.message' | translate
      "
    ></p>
    <button role="link" [routerLink]="origin()" class="btn btn-secondary">
      {{ "unauthenticated.register.payment-canceled.retry" | translate }}
    </button>
    <p>
      {{
        "unauthenticated.register.payment-canceled.feedback-message" | translate
      }}
    </p>
    } @if (!loading() && !showThankYou()) {
    <app-form
      [titleKey]="''"
      [fields]="formFields"
      [submitTextKey]="
        'unauthenticated.register.payment-canceled.feedback-submit'
      "
      [submitClass]="'btn-secondary'"
      [cancelClass]="'no-display'"
      (submit)="onSubmitFeedback($event)"
    ></app-form>
    } @else if (loading()) {
    <app-loading-spinner />
    } @else {
    <h2>
      {{ "unauthenticated.register.payment-canceled.thank-you" | translate }}
    </h2>
    <p>
      {{
        "unauthenticated.register.payment-canceled.thank-you-message"
          | translate
      }}
    </p>
    <button class="btn btn-dark" (click)="onBack()">
      {{ "generic.back" | translate }}
    </button>
    }
  </div>
</main>
