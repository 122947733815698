<div class="flex-row-start-center gap-5">
  @if (users() && alreadyAddedUsers()) {
  <app-multiselect
    [selectionItems]="users()"
    [selectedItems]="alreadyAddedUsers()"
    [searchProp]="'username'"
    [displayProp]="'username'"
    (selectedItemsChanged)="onUsersChanged($event)"
    [placeholder]="'workgroups.add-user.placeholder'"
  />
  <button class="btn-primary" [disabled]="buttonDisabled()" (click)="onSave()">
    {{ "generic.save" | translate }}
  </button>
  }
</div>
