import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { HandledError } from './handled-error';

export type ErrorType = 'input' | 'connection' | 'authorization' | 'subscription' | 'unknown' | 'not-found' | 'no-customer';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private readonly router: Router) {}

  private errors: HandledError[] = [];

  errorsChanged$ = new Subject<HandledError[]>();

  public setError(type: ErrorType, messageKey: string | null | undefined): void {
    if (this.errors.some(x => x.errorType === type)) {
      return;
    }
    if (type === 'subscription') {
      this.router.navigate(['/renew-subscription']);
      return;
    } else if (type === 'no-customer') {
      this.router.navigate(['/register']);
      return;
    }
    this.errors.push({
      errorType: type,
      errorMessageKey: this.getKeyOrDefaultByType(messageKey, type)
    });
    this.errorsChanged$.next(this.errors);
  }

  public clearError(): void {
    this.errors = [];
    this.errorsChanged$.next(this.errors);
  }

  private getKeyOrDefaultByType(key?: string | null | undefined, type?: ErrorType): string | undefined {
    if (key !== undefined && key !== null && key !== '') {
      return key;
    }
    if (!type) {
      return undefined;
    }
    switch (type) {
      case 'authorization':
        return 'errors.default.403';
      case 'not-found':
        return 'errors.default.404';
      case 'unknown':
        return 'errors.default.unknown';
      default:
        return undefined;
    }
  }

}
