<section class="container done-container">
  <fa-icon
  class="center success-icon"
  size="8x"
  [icon]="faCheckCircle"
  ></fa-icon>
  <div class="flex-column-start-center">
    @switch (registerStore.registrationProcessState())
    {
      @case ('checkingPayment') {
        <p>{{ "unauthenticated.register.done.checking-payment" | translate }}</p>
      }
      @case ('paymentFailed') {
        <p>{{ "unauthenticated.register.done.payment-failed" | translate }}</p>
      }
    }
    @if (registerStore.registrationProcessState() === 'data-validation-failed') {
      <h1>{{ "unauthenticated.register.done.data-validation-failed" | translate }}</h1>
      <button class="btn-secondary" (click)="backToDataInput()">
        {{ "unauthenticated.register.done.check-data" | translate }}
      </button>
    } @else {
      <h1>{{ "unauthenticated.register.done.title" | translate }}</h1>
      <p>{{ "unauthenticated.register.done.further-steps" | translate }}</p>
      <button class="btn-secondary" (click)="onClick()" [disabled]="!registerStore.canFinishRegistration()">
        {{ "unauthenticated.register.done.jump-to-app" | translate }}
      </button>
    }
  </div>
</section>
