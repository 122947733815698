import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { HttpService } from "../shared/http.service";

const apiUrl = environment.apiConfig.url;

@Injectable({ providedIn: 'root' })
export class ManageSubscriptionRepository {
  constructor(private readonly httpService: HttpService) {}

  cancelSubscription(): Observable<Date> {
    return this.httpService.get(`${apiUrl}/subscription/cancel-subscription`);
  }
}
