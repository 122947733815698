import { Component, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-number-slider',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './number-slider.component.html',
  styleUrl: './number-slider.component.scss'
})
export class NumberSliderComponent {
  min = input<number>(0);
  max = input<number>(100);
  value = input<number>(50);

  internalValue = signal<number>(0);

  changed = output<number>();

  ngOnChanges() {
    this.internalValue.set(this.value());
  }

  onChange(event: any) {
    this.changed.emit(event!.target!.value);
  }
}
