import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.loggedIn) {
    return route.data['roles'] == null || route.data['roles'].length === 0 || route.data['roles'].some((x: string) => x === authService.role);
  } else {
    const navigationExtras: NavigationExtras = {
      queryParams: { returnUrl: state.url },
    };

    return router.createUrlTree(['/home'], navigationExtras);
  }
};
