import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUserStore } from '../auth/current-user/current-user.store';
import { ProjectModel } from '../project/project.model';
import { DataTableColumn } from '../shared/data-table/data-table-column';
import { DataTableComponent } from '../shared/data-table/data-table.component';
import { LoadingSpinnerComponent } from '../shared/loading-spinner/loading-spinner.component';
import { AddWorkgroupComponent } from './add-workgroup/add-workgroup.component';
import { WorkgroupList, WorkgroupModel } from './workgroup.model';
import { WorkgroupRepository } from './workgroup.repository';

@Component({
    selector: 'app-user-workgroups',
    imports: [
    FontAwesomeModule,
    AddWorkgroupComponent,
    TranslateModule,
    LoadingSpinnerComponent,
    DataTableComponent
],
    templateUrl: './workgroup-list.component.html',
    styleUrl: './workgroup-list.component.scss'
})
export class WorkgroupListComponent implements OnInit, OnDestroy {
  workgroups: WorkgroupModel[] = [];
  destroy$ = new Subject<void>();
  rows: any[] = [];
  faPlus = faPlus;
  showAddDialog = false;
  loading = false;

  currentUserStore = inject(CurrentUserStore);

  columns: DataTableColumn[] = [
    {
      prop: 'name',
      name: 'Name',
      type: 'text',
      translateKey: 'workgroups.table.name',
    },
    {
      prop: 'projectCount',
      name: 'Projects',
      type: 'number',
      translateKey: 'workgroups.table.projects',
    },
    {
      prop: 'ownedBy',
      name: 'Owner',
      type: 'text',
      translateKey: 'workgroups.table.ownedBy',
    },
    {
      prop: 'createdAt',
      name: 'Created',
      type: 'datetime',
      translateKey: 'workgroups.table.createdAt',
    },
    {
      prop: 'updatedAt',
      name: 'Updated',
      type: 'datetime',
      translateKey: 'workgroups.table.updatedAt',
    },
  ];

  constructor(
    private readonly workgroupRepository: WorkgroupRepository,
    private readonly router: Router
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadWorkgroups(): void {
    this.loading = true;
    this.workgroupRepository
      .getWorkgroups()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result: WorkgroupList) => {
          this.workgroups = result.workgroups.slice();

          this.loading = false;
          this.rows = result.workgroups.map((wg) => {
            return {
              ...wg,
              ownedBy: wg.createdBy,
              createdAt: wg.createdAt,
              updatedAt: wg.updatedAt,
            };
          });
        },
        error: (error: any) => {
          this.loading = false;
        },
      });
  }

  ngOnInit(): void {
    this.loadWorkgroups();
  }

  onWorkgroupAdded(): void {
    this.loadWorkgroups();
    this.toggleShowAddDialog();
  }

  toggleShowAddDialog(): void {
    this.showAddDialog = !this.showAddDialog;
  }

  navigateToDetail = (row: ProjectModel) => {
    this.router.navigate(['/user/workgroups', row.id]);
  };
}
