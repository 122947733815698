import { KanbanModel } from '../kanban/kanban.model';

export class DefaultValues {

  kanban(): KanbanModel {
    return {
      lanes: [
        {
          id: '1',
          title: 'TODO',
          cards: [
            {
              id: '1',
              title: 'Card 1',
              description: 'Description 1',
              dueDate: new Date(),
            },
            {
              id: '2',
              title: 'Card 2',
              description: 'Description 2',
              dueDate: new Date(),
            },
          ],
        },
        {
          id: '2',
          title: 'WIP',
          cards: [
            {
              id: '3',
              title: 'Card 3',
              description: 'Description 3',
              dueDate: new Date(),
            },
          ],
        },
        {
          id: '3',
          title: 'DONE',
          cards: [
            {
              id: '4',
              title: 'Card 4',
              description: 'Description 4',
              dueDate: new Date(),
            },
          ],
        },
      ],
    };
  }
}
