import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { Sku } from '../models/enums/sku.enum';
import { DataTableColumn } from '../shared/data-table/data-table-column';
import { DataTableComponent } from '../shared/data-table/data-table.component';
import { LoadingSpinnerComponent } from '../shared/loading-spinner/loading-spinner.component';
import { CustomerModel } from './customer.model';
import { CustomerRepository } from './customer.repository';

@Component({
  selector: 'app-admin-customers',
  standalone: true,
  imports: [
    RouterModule,
    TranslateModule,
    LoadingSpinnerComponent,
    DataTableComponent,
    RouterModule,
  ],
  templateUrl: './admin-customers.component.html',
  styleUrl: './admin-customers.component.scss',
})
export class AdminCustomersComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  faTrash = faTrash;
  loading = false;

  rows: any[] = [];
  columns: DataTableColumn[] = [
    {
      prop: 'name',
      name: 'Name',
      type: 'text',
      translateKey: 'admin.customers.table.name',
    },
    {
      prop: 'vatId',
      name: 'VAT ID',
      type: 'text',
      translateKey: 'admin.customers.table.vatId',
    },
    {
      prop: 'activeUsers',
      name: 'Active Users',
      type: 'number',
      translateKey: 'admin.customers.table.activeUsers',
    },
    {
      prop: 'inactiveUsers',
      name: 'Inactive Users',
      type: 'number',
      translateKey: 'admin.customers.table.inactiveUsers',
    },
    {
      prop: 'overallUsers',
      name: 'Overall Users',
      type: 'number',
      translateKey: 'admin.customers.table.overallUsers',
    },
    {
      prop: 'givenUserCount',
      name: 'Given User Count',
      type: 'number',
      translateKey: 'admin.customers.table.givenUserCount',
    },
    {
      prop: 'sku',
      name: 'SKU',
      type: 'enum',
      translateKey: 'admin.customers.table.sku',
      enumStates: [
        { forValue: Sku.Basic, class: 'primary', textKey: 'sku.0' },
        { forValue: Sku.Startup, class: 'secondary', textKey: 'sku.1' },
        { forValue: Sku.Pro, class: 'light', textKey: 'sku.2' },
        { forValue: Sku.Enterprise, class: 'dark', textKey: 'sku.3' },
      ]
    },
    {
      prop: 'createdAt',
      name: 'Created At',
      type: 'datetime',
      translateKey: 'admin.customers.table.createdAt',
    },
    {
      prop: 'updatedAt',
      name: 'Updated At',
      type: 'datetime',
      translateKey: 'admin.customers.table.updatedAt',
    },
    {
      prop: 'active',
      name: 'Active',
      type: 'boolean',
      translateKey: 'admin.customers.table.active',
    },
    {
      prop: 'actions',
      name: 'Actions',
      type: 'actions',
      translateKey: 'admin.customers.table.actions',
      actions: [
        {
          tooltip: 'Toggle Active',
          func: (row: any) => {
            const cell = row.cells.find((c: any) => c.column === 'active');
            this.toggleCustomerActive(row.id, cell.value);
          },
          buttonClassFn: (row: any) => {
            const cell = row.cells.find((c: any) => c.column === 'active');
            return cell.value ? 'btn-warning' : 'btn-success';
          },
          buttonTextKeyFn: (row: any) => {
            const cell = row.cells.find((c: any) => c.column === 'active');
            return cell.value
              ? 'admin.customers.table.deactivate'
              : 'admin.customers.table.activate';
          },
        },
        {
          icon: faTrash,
          tooltip: 'Delete',
          func: (row: any) => this.deleteCustomer(row.id),
          buttonClass: 'btn-danger',
          buttonTextKey: 'admin.customers.table.delete',
        },
      ],
    },
  ];

  constructor(
    private readonly customerRepository: CustomerRepository,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.loadCustomers();
  }

  loadCustomers(): void {
    this.loading = true;
    this.customerRepository
      .getCustomers()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (customers) => {
          this.loading = false;
          this.rows = customers.map((c) => {
            return {
              id: c.id,
              name: c.name,
              vatId: c.vatId,
              givenUserCount: c.givenUserCount,
              sku: c.sku,
              createdAt: c.createdAt,
              updatedAt: c.updatedAt,
              active: c.active,
            };
          });
          this.loadCustomerCounts();
        },
        error: (error) => {
          this.loading = false;
        },
      });
  }

  loadCustomerCounts(): void {
    this.loading = true;
    for (const customerId of this.rows.map((c) => c.id)) {
      this.customerRepository
        .getUserCountOfCustomer(customerId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (userCount) => {
            const row = this.rows.find((r) => r.id === customerId);
            if (row) {
              row.activeUsers = userCount.activeUsers;
              row.inactiveUsers = userCount.inactiveUsers;
              row.overallUsers = userCount.overallUsers;
            }
            this.loading = false;
          },
          error: (error) => {
            console.error(error);
            this.loading = false;
          },
        });
    }
  }

  toggleCustomerActive(id: string, currentlyActive: boolean): void {
    this.translateService
      .get(
        currentlyActive
          ? 'admin.customers.deactivate'
          : 'admin.customers.activate'
      )
      .subscribe({
        next: (translation) => {
          if (confirm(translation)) {
            this.customerRepository
              .toggleCustomerActive(id)
              .pipe(takeUntil(this.destroyed$))
              .subscribe({
                next: () => {
                  this.loadCustomers();
                },
                error: (error) => {
                  console.error(error);
                },
              });
          }
        },
      });
  }

  deleteCustomer(id: string): void {
    if (confirm('Are you sure you want to delete this customer?')) {
      this.customerRepository
        .deleteCustomer(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.loadCustomers();
        });
    }
  }

  onSelect = (customer: CustomerModel) => {
    this.router.navigate(['/admin', 'customers', customer.id]);
  };
}
