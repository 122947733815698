import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { MultiselectService } from '../multiselect.service';

type MultiselectState = {
  selectedItems: any[];
  availableItems: any[];
  searchedItems: any[];
  searchProp: string;
  displayProp: string;
};

const initialState: MultiselectState = {
  selectedItems: [],
  availableItems: [],
  searchedItems: [],
  searchProp: 'name',
  displayProp: 'name',
};

export const MultiselectStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store, multiselectService = inject(MultiselectService)) => ({
    searchItem(searchTerm?: string) {
      if (searchTerm && searchTerm.length > 0) {
        const result =
        multiselectService.filterThatsAlreadyPresent(
        multiselectService.filterData(
          store.availableItems(),
          store.searchProp(),
          searchTerm
        ), store.selectedItems(), store.searchProp());
        patchState(store, { searchedItems: result });
      } else {
        patchState(store, {
          searchedItems: multiselectService.filterThatsAlreadyPresent(
            store.availableItems(),
            store.selectedItems(),
            store.searchProp()
          ),
        });
      }
    },
    setSelectedItems(items: any[]) {
      patchState(store, (state) => ({ selectedItems: items }));
      patchState(store, {
        searchedItems: multiselectService.filterThatsAlreadyPresent(
          store.availableItems(),
          store.selectedItems(),
          store.searchProp()
        ),
      });
    },
    removeItem(item: any) {
      const filtered = store.selectedItems().filter((x) => x[store.searchProp()] !== item[store.searchProp()]);
      patchState(store, { selectedItems: filtered });
    },
    setAvailableItems(items: any[]) {
      patchState(store, (state) => ({ availableItems: items }));
    },
    setSearchProp(prop: string) {
      patchState(store, (state) => ({ searchProp: prop }));
    },
    setDisplayProp(prop: string) {
      patchState(store, (state) => ({ displayProp: prop }));
    }
  })),
  withDevtools('multiselect')
);
