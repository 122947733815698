<h1>{{ "workgroups.detail.title" | translate }}</h1>
<a class="back-button" (click)="backToList()">
  <fa-icon [icon]="faArrowLeft"></fa-icon>
  {{ "workgroups.detail.backButton" | translate }}
</a>
@if (loadingDetail) {
<app-loading-spinner />
} @else {
<div class="workgroup-detail-header">
  <h2 [title]="workgroup.id">{{ workgroup.name }}</h2>
  @if (workgroup.ownerId === currentUserStore.id() || currentUserStore.isElevatedUser()) {
  <div class="workgroup-detail-header__btn-group">
    @if (currentUserStore.isElevatedUser()) {
      <button class="btn-danger" (click)="deleteWorkgroup()">
        <fa-icon [icon]="faTrash"></fa-icon>
        {{ "workgroups.detail.deleteButton" | translate }}
      </button>
    }
    <button class="btn-warning" (click)="editWorkgroup()">
      <fa-icon [icon]="faEdit"></fa-icon>
      {{ "workgroups.detail.editButton" | translate }}
    </button>
  </div>
  }
</div>
@if (workgroup.ownerId === currentUserStore.id() || currentUserStore.isElevatedUser()) {
<h3>{{ "workgroups.detail.access" | translate }}</h3>
<div class="workgroup-detail-manage-users">
  <app-add-user-workgroup [workgroupId]="workgroup.id" />
</div>
}
<h3>{{ "workgroups.detail.projects" | translate }}</h3>
<div class="projects">
  <button class="btn-success" (click)="toggleAddProjectDialog()">
    <fa-icon [icon]="faPlus" />
    {{ "workgroups.detail.newProjectButton" | translate }}
  </button>
  <app-test-version-legend [sku]="1" [isDiagramButton]="true"></app-test-version-legend>
</div>
<app-add-project
  [showDialog]="showAddProjectDialog"
  (canceled)="toggleAddProjectDialog()"
  (projectAdded)="onProjectAdded()"
  [workgroupId]="workgroup.id"
/>
@if (loadingProjects) {
<app-loading-spinner />
} @else {
<app-data-table
  [columns]="columns"
  [data]="rows"
  [onSelectRow]="selectRow"
></app-data-table>
} }
@if (editWorkgroupFields()) {
  <app-dialog [showDialog]="showEditWorkgroupDialog()">
    <app-form [titleKey]="'workgroups.detail.edit.title'" [fields]="editWorkgroupFields()!" (submit)="onEditSave($event)" (cancel)="onEditCancel()"></app-form>
  </app-dialog>
}
