<section class="payment-container">
  <h1>{{ 'unauthenticated.register.payment.title' | translate }}</h1>
  @if (!registerStore.paymentCompleted()) {
    <p>{{ 'unauthenticated.register.payment.description' | translate }}</p>
    @if (checkoutLink()) {
    <a class="payment-button" [href]="checkoutLink()">
      <fa-icon size="10x" [icon]="faEuroSign"></fa-icon>
    </a>
    } @else {
      <app-loading-spinner />
    }
    <div class="btn-group center">
      <button class="btn-dark" (click)="goBack()">{{ 'generic.back' | translate }}</button>
    </div>
  } @else {
    @if (activatingAccount()) {
    <p>{{ 'unauthenticated.register.payment.activating-account' | translate }}</p>
    <app-loading-spinner />
    }
    @if (activationError()) {
    <p>{{ 'unauthenticated.register.payment.activation-error' | translate }}</p>
    }
  }
</section>
