import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../shared/http.service';
import { UserModel, UserState } from '../user.model';

const apiConfig = environment.apiConfig;

export type CustomerUsersState = {
  users: UserModel[];
  maxUsers: number;
  loading: boolean;
};

const initialState: CustomerUsersState = {
  users: [],
  maxUsers: 0,
  loading: false,
};

export const CustomerUsersStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ users, maxUsers }) => ({
    userCount: computed(() => users().length),
    activeUserCount: computed(
      () => users().filter((user) => user.userState === UserState.Active).length
    ),
    inactiveUserCount: computed(
      () =>
        users().filter((user) => user.userState === UserState.Inactive).length
    ),
    maxUsersReached: computed(() => users().filter((user) => user.userState === UserState.Active).length >= maxUsers()),
  })),
  withDevtools('CustomerUsersStore'),
  withMethods((store, httpService = inject(HttpService)) => ({
    load: () => {
      patchState(store, { loading: true });
      httpService.get<number>(apiConfig.url + '/customer/max-users').subscribe({
        next: (result) => {
          patchState(store, { maxUsers: result });
          httpService
            .get<UserModel[]>(apiConfig.url + '/customer/all-users')
            .subscribe({
              next: (users) => {
                patchState(store, { users, loading: false });
              },
            });
        },
      });
    },
  }))
);
