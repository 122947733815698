import { Component, inject, input, OnDestroy, output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { FormField } from '../../../shared/form/form-field';
import { FormComponent } from '../../../shared/form/form.component';
import { CustomerUserRepository } from '../customer-user.repository';

@Component({
  selector: 'app-invite-user',
  standalone: true,
  imports: [DialogComponent, FormComponent],
  templateUrl: './invite-user.component.html',
  styleUrl: './invite-user.component.scss',
})
export class InviteUserComponent implements OnDestroy {

  destroy$ = new Subject<void>();
  showDialog = input<boolean>(false);

  submitted = output<void>();
  canceled = output<void>();

  customerUserRepository = inject(CustomerUserRepository);

  formFields: FormField[] = [
    {
      name: 'email',
      labelKey: 'organization-users.add.email',
      fieldType: 'email',
      validators: [Validators.email],
      required: true,
    },
    {
      name: 'role',
      labelKey: 'organization-users.add.role',
      fieldType: 'select',
      required: true,
      defaultValue: 'User',
      selectOptions: [
        {
          key: 'User',
          value: 'User',
          translateKey: 'organization-users.add.user',
        },
        {
          key: 'CustomerManager',
          value: 'CustomerManager',
          translateKey: 'organization-users.add.customerManager',
        },
      ],
    },
  ];

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(form: FormGroup) {
    this.customerUserRepository.createCustomerUser(form.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.submitted.emit();
        },
      });
  }

  onCancel() {
    this.canceled.emit();
  }
}
