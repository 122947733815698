import { CdkDragEnd, DragDropModule } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';
import { clamp } from '../../shared/clamp';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { FormField } from '../../shared/form/form-field';
import { FormComponent } from '../../shared/form/form.component';
import { DiagramRepository } from '../diagram.repository';
import { LastEditedComponent } from '../last-edited/last-edited.component';
import { DiagramModel } from '../models/diagram.model';
import { RiskMatrixModel, RiskModel } from './risk-matrix.model';

@Component({
  selector: 'app-risk-matrix',
  standalone: true,
  imports: [
    TranslateModule,
    FontAwesomeModule,
    DialogComponent,
    FormComponent,
    DragDropModule,
    LastEditedComponent
  ],
  templateUrl: './risk-matrix.component.html',
  styleUrl: './risk-matrix.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskMatrixComponent implements OnInit {
  diagramId = input<string | undefined>(undefined);
  showAddRiskDialog = signal<boolean>(false);
  diagram = signal<DiagramModel | undefined>(undefined);
  riskData = signal<RiskMatrixModel | undefined>(undefined);

  faArrowLeft = faArrowLeft;
  faTriangleExclamation = faTriangleExclamation;
  faArrowRight = faArrowRight;
  faTrash = faTrash;

  fields: FormField[] = [
    {
      name: 'name',
      labelKey: 'diagrams.risk-matrix.dialog.name',
      fieldType: 'text',
      required: true,
    },
    {
      name: 'description',
      labelKey: 'diagrams.risk-matrix.dialog.description',
      fieldType: 'textarea',
      required: false,
    },
  ];

  constructor(
    private readonly diagramRepository: DiagramRepository,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadDiagram();
  }

  addRisk() {
    this.showAddRiskDialog.set(true);
  }

  loadDiagram(): void {
    this.diagramRepository.get(this.diagramId()!).subscribe({
      next: (diagram) => {
        this.diagram.set(diagram);
        this.riskData.set(JSON.parse(diagram.diagramJson));
      },
    });
  }

  updateDiagram() {
    const diagram = this.diagram();
    if (diagram) {
      const diagramData = { type: 'risk-matrix', ...this.riskData() };
      diagram.diagramJson = JSON.stringify(diagramData);
      this.diagramRepository.update(diagram).subscribe({
        next: () => {
          this.loadDiagram();
        },
      });
    }
  }

  backToDiagrams(): void {
    if (!this.diagram()?.projectId !== null) {
    this.router.navigate(['user/dashboard/', this.diagram()?.projectId ?? '']);
    } else {
      this.router.navigate(['user/dashboard/private']);
    }
  }

  onAddRisk($event: FormGroup) {
    const risk = $event.value;
    const risks = this.riskData()?.risks ?? [];
    risk.id = uuid();
    risk.position = { x: 0, y: 0 };
    risks.push(risk);
    this.riskData.set({ risks });
    this.showAddRiskDialog.set(false);
    this.updateDiagram();
  }

  onDragEnd(event: CdkDragEnd<RiskModel>) {
    const risks = this.riskData()?.risks ?? [];
    const risk = risks.find((r) => r.id === event.source.data.id);
    if (risk) {
      const parentElementBoundingRect =
        event.source.element.nativeElement.parentElement!.getBoundingClientRect();
      const clientElementBoundingRect =
        event.source.element.nativeElement.getBoundingClientRect();

      const x = clamp(
        ((clientElementBoundingRect.left - parentElementBoundingRect.left) /
          parentElementBoundingRect.width) *
          100,
        0,
        100
      );
      const y = clamp(
        ((clientElementBoundingRect.bottom - parentElementBoundingRect.bottom) /
          -parentElementBoundingRect.height) *
          100,
        0,
        100
      );

      risk.position = { x: x, y: y };

      this.riskData.set({ risks });
      event.source.reset();
      this.updateDiagram();
    }
  }

  deleteRisk(id: string) {
    this.translateService
      .get('diagrams.risk-matrix.delete-risk')
      .subscribe({
        next: (message) => {
          if (confirm(message)) {
            const risks = this.riskData()?.risks ?? [];
            const risk = risks.find((r) => r.id === id);
            if (risk) {
              const index = risks.indexOf(risk);
              risks.splice(index, 1);
              this.riskData.set({ risks });
              this.updateDiagram();
            }
          }
        },
      });
  }

  onCancelAddRisk() {
    this.showAddRiskDialog.set(false);
  }
}
