import { Component, input, OnDestroy, signal } from '@angular/core';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { DiagramRepository } from '../../diagrams/diagram.repository';
import { DiagramModel } from '../../diagrams/models/diagram.model';
import { NewDiagramComponent } from '../../diagrams/new-diagram/new-diagram.component';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { TestVersionLegendComponent } from "../../shared/test-version-legend/test-version-legend.component";
import { CardPreviewComponent } from './card-preview/card-preview.component';

@Component({
  selector: 'app-project-cards',
  standalone: true,
  imports: [
    LoadingSpinnerComponent,
    TranslateModule,
    NewDiagramComponent,
    CardPreviewComponent,
    FontAwesomeModule,
    TestVersionLegendComponent
],
  templateUrl: './project-cards.component.html',
  styleUrl: './project-cards.component.scss',
})
export class ProjectCardsComponent implements OnDestroy {
  $destroy = new Subject<void>();

  projectId = input<string>('private');
  diagrams = signal<DiagramModel[]>([]);
  loading = signal<boolean>(false);
  showPriorityAddDialog = signal<boolean>(false);

  faUser = faUser;

  constructor(
    private readonly router: Router,
    private readonly diagramRepository: DiagramRepository
  ) {}

  ngOnChanges(): void {
    this.loadDiagrams();
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  loadDiagrams(): void {
    this.loading.set(true);

    if (!this.projectId()) {
      this.loading.set(false);
      return;
    }

    if (this.projectId() === 'private') {
      this.diagramRepository
        .getPrivate()
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          next: (diagrams) => {
            this.diagrams.set(diagrams);
            this.loading.set(false);
          },
          error: () => {
            this.loading.set(false);
          },
        });
      return;
    }

    this.diagramRepository
      .getForProject(this.projectId()!)
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: (diagrams) => {
          this.diagrams.set(diagrams);
          this.loading.set(false);
        },
        error: () => {
          this.loading.set(false);
        },
      });
  }

  onNewPriority(): void {
    this.showPriorityAddDialog.set(true);
  }

  onPriorityLoading(loading: boolean): void {
    this.loading.set(loading);
  }

  onPriorityCreated(id: string): void {
    this.showPriorityAddDialog.set(false);
    this.router.navigate(['user', 'diagrams', id]);
  }

  onPriorityCanceled(): void {
    this.showPriorityAddDialog.set(false);
  }

  onCardClicked(id: string): void {
    this.router.navigate(['user', 'diagrams', id]);
  }
}
