export enum UserState {
  Invited = 0,
  Inactive = 1,
  Active = 2,
}

export interface UserModel {
  id: string;
  username: string;
  createdAt: Date;
  emailAddress?: string;
  userState: UserState;
  customerId?: string;
  role: string;
  customerActive: boolean;
}
