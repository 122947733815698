<table class="datatable">
  <thead class="datatable-header">
    <tr class="datatable-header-row">
      @for (column of columns; track column.prop) {
      <th class="datatable-header-cell">
        @if (column.translateKey) {
        {{ column.translateKey | translate }}
        } @else if (column.name) {
        {{ column.name }}
        } @else {
        {{ column.prop }}
        }
      </th>
      }
    </tr>
  </thead>
  <tbody class="datatable-body">
    @for (row of rows; track $index; let odd = $odd; let even = $even) {
    <tr
      (click)="onSelectRow(row)"
      class="datatable-row-wrapper datatable-body-row"
      [class.datatable-row-odd]="odd"
      [class.datatable-row-even]="even"
    >
      @for (cell of row.cells; track cell.value) { @switch (cell.type) { @case
      ('text') {
      <td class="datatable-body-cell">{{ cell.value }}</td>
      } @case('number') {
      <td class="datatable-body-cell">{{ cell.value ?? 0 | number }}</td>
      } @case ('date') { @if (cell.value) {
      <td class="datatable-body-cell">{{ cell.value | date : 'dd.MM.yyyy' }}</td>
      } @else {
      <td class="datatable-body-cell">-</td>
      } } @case ('datetime') { @if (cell.value) {
      <td class="datatable-body-cell">
        {{ cell.value | date : "dd.MM.yyyy HH:mm" }}
      </td>
      } @else {
      <td class="datatable-body-cell">-</td>
      } } @case ('currency') {
      <td class="datatable-body-cell">{{ cell.value | currency }}</td>
      } @case ('percentage') {
      <td class="datatable-body-cell">
        <progress value="{{ cell.value }}" max="100"></progress>
        {{ cell.value | number : "1.0-2" }}%
      </td>
      } @case('actions') {
      <td class="datatable-body-cell">
        <div class="btn-group wrap">
          @for (action of cell.actions; track $index) {
          <button
            [class]="action.buttonClass ?? action.buttonClassFn(row)"
            (click)="executeAction($event, row, action.func)"
            [disabled]="action.isDisabledFn ? action.isDisabledFn(row) : false"
          >
          @if (action.icon) {
            <fa-icon [icon]="action.icon"></fa-icon>
          }
            {{
              action.buttonTextKey ?? action.buttonTextKeyFn(row) | translate
            }}
          </button>
          }
        </div>
      </td>
      } @case('boolean') {
      <td class="datatable-body-cell">
        <input type="checkbox" [checked]="cell.value" disabled />
      </td>
      } @case('time') {
      <td class="datatable-body-cell">{{ cell.value | date : "HH:mm" }}</td>
      } @case('enum') {
      <td class="datatable-body-cell">
        @for (status of cell.enumStates; track $index) { @if (status.forValue
        === cell.value) {
        <span
          class="badge"
          [class]="status.class"
        >
          @if (status.icon) {
          <fa-icon size="lg" [icon]="status.icon"></fa-icon>
          }
          {{ status.textKey | translate }}
        </span>
        } }
      </td>
      } @default {
      <td class="datatable-body-cell">{{ cell.value }}</td>
      } } }
    </tr>
    } @empty {
    <tr class="datatable-row-wrapper--no-click datatable-body-row">
      <td class="datatable-body-cell" [attr.colspan]="columns.length">
        <div class="center">{{ "generic.no-data" | translate }}</div>
      </td>
    </tr>
    }
  </tbody>
</table>
