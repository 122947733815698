<h1>{{ 'admin.customers.title' | translate }}</h1>
<div class="admin-customers-btn-group">
  <a class="admin-customers-btn-group__add-customer-btn" routerLink="/admin/customers/add">
    {{ 'admin.customers.addCustomerButton' | translate }}
  </a>
</div>
@if (loading) {
  <app-loading-spinner />
} @else {
<app-data-table
  [columns]="columns"
  [data]="rows"
  [onSelectRow]="onSelect"
  />
}
