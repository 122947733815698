import { CurrencyPipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SkuModel } from '../../sku.model';
import { FeatureItemComponent } from '../feature-item/feature-item.component';

@Component({
  selector: 'app-sku-item',
  standalone: true,
  imports: [TranslateModule, CurrencyPipe, FeatureItemComponent],
  templateUrl: './sku-item.component.html',
  styleUrl: './sku-item.component.scss',
  host: {
    '[class.display-only]': 'displayOnly()',
  },
})
export class SkuItemComponent {
  sku = input<SkuModel>();
  displayOnly = input<boolean>();
  freeVersion = input<boolean>();
}
