<section appFeatureDisplay [minimumFeatureSku]="1" class="selector-wrapper">
  <h2>{{ "projects.selector.title" | translate }}</h2>

  <select [disabled]="loading" [(ngModel)]="selectedProjectId" (change)="onSelect($event)">
    <option value="private">{{ "projects.selector.private" | translate }}</option>
    @for(project of projects; track project.id) {
    <option [value]="project.id">{{ project.name }}</option>
    }
  </select>
  <app-test-version-legend [sku]="1" [height]="28"></app-test-version-legend>
  @if (loading) {
  <app-loading-spinner [minimal]="true" />
  }
</section>
