<div
  class="issue"
  [class.issue--no-move]="displayOnly"
  [class.no-scale]="displayOnly"
>
  <p class="issue-title">{{ issue.title }}</p>
  <p class="issue-description">{{ issue.description }}</p>
  <p class="issue-date">{{ issue.updated | date : "dd.MM.yyyy HH:mm" }}</p>
  @if (!archived()) {
  <button
    class="btn-danger remove-issue-button"
    (click)="triggerRemoveDialog()"
  >
    <fa-icon [icon]="faTrash"></fa-icon>
  </button>
  @if (!displayOnly) {
  <button class="btn-success done-issue-button" (click)="markAsDone()">
    <fa-icon [icon]="faCheck"></fa-icon>
  </button>
  } } @if (displayOnly) {
  <span
    class="issue-priority-letter"
    [class.issue-priority-letter--a]="issue.priority === 'A'"
    [class.issue-priority-letter--b]="issue.priority === 'B'"
    [class.issue-priority-letter--c]="issue.priority === 'C'"
    [class.issue-priority-letter--d]="issue.priority === 'D'"
    [class.issue-priority-letter--none]="issue.priority === 'None'"
    >{{ priorityLetter }}</span
  >
  <span
    class="issue-state"
    [class.issue-state--done]="issue.state === 'done'"
    [class.issue-state--removed]="issue.state === 'removed'"
  >
    {{ "diagrams.priority.state." + issue.state | translate }}
  </span>
  }
</div>
<app-confirmation-dialog
  [show]="showRemoveDialog() || showRemoveForeverDialog()"
  [messageKey]="
    (this.displayOnly
      ? 'diagrams.priority.remove-forever.text'
      : 'diagrams.priority.remove-issue.text')
  "
  (confirm)="this.displayOnly ? removeForever.emit() : remove.emit()"
  (cancel)="cancelRemove()"
/>
