@if (hasError && !dismissed) {
<div class="error-container">
  <div class="error-container__errors">
    @for (error of errors; track $index) {
    <div class="error-container__errors__single">
      <fa-icon size="xl" class="error-icon" [icon]="error.icon"></fa-icon>
      <span class="error-text">{{
        error.error.errorMessageKey! | translate
      }}</span>
    </div>
    }
  </div>

  <div class="error-container__button">
    <button class="btn-danger" (click)="toggleDismissed()">X</button>
  </div>
</div>
} @if (hasError && dismissed) {
<div class="error-container error-container--dismissed">
  @for (error of errors; track $index) {
  <fa-icon
    size="xl"
    class="error-icon"
    [icon]="error.icon"
    (click)="toggleDismissed()"
  ></fa-icon>
  }
</div>
}
