import { Component, input, OnInit, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DiagramModel } from '../../../diagrams/models/diagram.model';
import { TestVersionLegendComponent } from '../../../shared/test-version-legend/test-version-legend.component';

@Component({
  selector: 'app-card-preview',
  standalone: true,
  imports: [TranslateModule, TestVersionLegendComponent],
  templateUrl: './card-preview.component.html',
  styleUrl: './card-preview.component.scss'
})
export class CardPreviewComponent implements OnInit {
  diagram = input<DiagramModel>();
  type = signal<string | undefined>(undefined);

  ngOnInit(): void {
    const type = JSON.parse(this.diagram()!.diagramJson).type;
    this.type.set(type);
  }
}
