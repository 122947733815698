import { withDevtools, withStorageSync } from "@angular-architects/ngrx-toolkit";
import { computed } from "@angular/core";
import { patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { SkuModel } from "../unauthenticated/register-wizard/sku.model";


export type RenewSubscriptionState = {
  selectedSku: SkuModel | undefined;
  currentCustomerId: string | undefined;
  loading: boolean;
  loadingPaymentLink: boolean;
  paymentLinkLoaded: boolean;
  paymentLink: string | undefined;
  amountOfUsers: number | undefined;
  sessionId: string | undefined;
  paymentCompleted: boolean;
}

const initialState: RenewSubscriptionState = {
  selectedSku: undefined,
  currentCustomerId: undefined,
  loading: false,
  loadingPaymentLink: false,
  paymentLinkLoaded: false,
  paymentLink: undefined,
  amountOfUsers: 1,
  sessionId: undefined,
  paymentCompleted: false,
}

export const RenewSubscriptionStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ selectedSku, amountOfUsers, sessionId }) => ({
    defaultAmountOfUsers: computed(() => {
      if (selectedSku()) {
        return Math.ceil(selectedSku()!.maxAmountOfUsers / 2);
      } else {
        return 1;
      }
    }),
    expectedCost: computed<number>(() => {
      if (selectedSku()) {
        return selectedSku()!.price * amountOfUsers()!;
      } else {
        return 0;
      }
    }),
    canCompletePayment: computed(() => !!sessionId()),
  })),
  withDevtools('RenewSubscriptionStore'),
  withStorageSync('RenewSubscriptionStore'),
  withMethods((store) => ({
    selectSku: (sku: SkuModel) => patchState(store, { selectedSku: sku }),
    setAmountOfUsers: (amount: number) => patchState(store, { amountOfUsers: amount > store.selectedSku()!.maxAmountOfUsers ? store.selectedSku()!.maxAmountOfUsers : amount }),
    setLoading: (loading: boolean) => patchState(store, { loading }),
    setLoadingPaymentLink: (loading: boolean) => patchState(store, { loadingPaymentLink: loading }),
    setPaymentLinkLoaded: (loaded: boolean) => patchState(store, { paymentLinkLoaded: loaded }),
    setPaymentLink: (link: string) => patchState(store, { paymentLink: link }),
    setCurrentCustomerId: (customerId: string) => patchState(store, { currentCustomerId: customerId }),
    setSessionId: (sessionId: string) => patchState(store, { sessionId: sessionId }),
    reset: () => patchState(store, initialState),
    setPaymentCompleted: (completed: boolean) => patchState(store, { paymentCompleted: completed }),
  })
));
