<app-dialog [showDialog]="showDialog">
  <div style="height: 300px">
    @if (fields.length > 0) {
    <app-form
      [fields]="fields"
      [titleKey]="'workgroups.addDialog.title'"
      (submit)="addWorkgroup($event)"
      (cancel)="cancel()"
    ></app-form>
    }
  </div>
</app-dialog>
