import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CurrentUserStore } from '../auth/current-user/current-user.store';
import { FeatureStore } from '../shared/features/feature.store';
import { LanguageSwitchComponent } from "../shared/language-switch/language-switch.component";
import { LogoComponent } from "../shared/logo/logo.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterLink, TranslateModule, LogoComponent, LanguageSwitchComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  currentUserStore = inject(CurrentUserStore);
  featureStore = inject(FeatureStore);
  isLoggedIn = signal(false);
  interval!: NodeJS.Timeout;

  public get isAdmin(): boolean {
    return this.currentUserStore.role() === 'Admin';
  }

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
      this.interval = setInterval(() => {if (this.currentUserStore.loggedIn()) {
        clearInterval(this.interval);
        this.isLoggedIn.set(true);
        this.featureStore
        .getFeatures()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.navigateToRespectiveDashboard();
        });
      }
    }, 1000);
  }

  private navigateToRespectiveDashboard() {
    if (this.isAdmin) {
      this.router.navigate(['/admin']);
    } else {
      this.router.navigate(['/user']);
    }
  }

  protected login() {
    if (this.currentUserStore.loggedIn() || this.authService.loggedIn) {
      this.currentUserStore.loginAndSetUserInfo();
      this.router.navigate(['/home']);
    } else {
      this.authService.login();
    }
  }
}
