import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerRepository } from '../admin-customers/customer.repository';
import { DataGroupComponent } from '../shared/data-group/data-group.component';
import { DataPointComponent } from '../shared/data-point/data-point.component';
import { LoadingSpinnerComponent } from '../shared/loading-spinner/loading-spinner.component';
import { CustomerDetailModel } from './customer-detail.model';
import { UsersComponent } from './users/users.component';

@Component({
  selector: 'app-admin-customer-detail',
  standalone: true,
  imports: [
    TranslateModule,
    RouterModule,
    LoadingSpinnerComponent,
    DataPointComponent,
    DataGroupComponent,
    UsersComponent
  ],
  templateUrl: './admin-customer-detail.component.html',
  styleUrl: './admin-customer-detail.component.scss',
})
export class AdminCustomerDetailComponent {
  customerId!: string;
  customer!: CustomerDetailModel;
  loading: boolean = false;
  error: boolean = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly customerService: CustomerRepository,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.customerId = this.activatedRoute.snapshot.params['id'];
    this.loadCustomer();
  }

  loadCustomer() {
    this.loading = true;
    this.customerService.getById(this.customerId).subscribe({
      next: (customer) => {
        this.customer = customer;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.error = true;
      },
    });
  }

  editCustomer() {
    this.router.navigate(['admin', 'customers', this.customerId, 'edit']);
  }
}
