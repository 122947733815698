import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-switch',
  standalone: true,
  imports: [],
  templateUrl: './language-switch.component.html',
  styleUrl: './language-switch.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitchComponent implements OnInit {
  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.currentlySelected.set(this.translateService.currentLang);
  }

  currentlySelected = signal('de');
  showDropdown = signal(false);

  toggleDropdown(): void {
    this.showDropdown.set(!this.showDropdown());
  }

  setLanguage(language: string): void {
    this.translateService.use(language);
    this.currentlySelected.set(language);
    this.showDropdown.set(false);
  }
}
