import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpService } from "../../shared/http.service";
import { InviteUserModel } from "./models/invite-user.model";

const apiConfig = environment.apiConfig;

@Injectable({
  providedIn: 'root'
})
export class CustomerUserRepository {
  constructor(private readonly httpService: HttpService) {}

  createCustomerUser(model: InviteUserModel): Observable<void> {
    return this.httpService.post<void>(`${apiConfig.url}/customer/add-user`, model);
  }

  toggleActiveUser(userId: string): Observable<void> {
    return this.httpService.get<void>(`${apiConfig.url}/customer/toggle-user/${userId}`);
  }

  updateUserRole(userId: string, role: string): Observable<void> {
    return this.httpService.post<void>(`${apiConfig.url}/customer/update-user-role/${userId}/${role}`, null);
  }

  deleteUser(userId: string): Observable<void> {
    return this.httpService.delete<void>(`${apiConfig.url}/customer/delete-user/${userId}`);
  }
}
