<h1>{{ "admin.customer.addForm.title" | translate }}</h1>
<form [formGroup]="addCustomerForm" class="formGroup" (ngSubmit)="onSubmit()">
  <div class="formGroup__inputs">
    <div class="formGroup__section">
      <label for="name"
        >{{ "admin.customer.addForm.name" | translate
        }}<span class="req">*</span></label
      >
      <input id="name" type="text" formControlName="name" required />
      <label for="adminEmail"
        >{{ "admin.customer.addForm.customerAdminEmail" | translate
        }}<span class="req">*</span></label
      >
      <input
        id="adminEmail"
        type="email"
        formControlName="adminEmail"
        required
      />
    </div>
    <div class="formGroup__section">
      <label for="sku"
        >{{ "admin.customer.addForm.sku" | translate
        }}<span class="req">*</span></label
      >
      <select id="sku" type="text" formControlName="sku" required="true">
        <option value="0">{{ "sku.0" | translate }}</option>
        <option value="1">{{ "sku.1" | translate }}</option>
        <option value="2">{{ "sku.2" | translate }}</option>
        <option value="3">{{ "sku.3" | translate }}</option>
      </select>
      <label for="givenUserCount"
        >{{ "admin.customer.addForm.amountOfUsers" | translate
        }}<span class="req">*</span></label
      >
      <input
        id="givenUserCount"
        type="number"
        formControlName="givenUserCount"
        required
      />
      <label for="tenantId"
        >{{ "admin.customer.addForm.tenantId" | translate
        }}<span class="req">*</span></label
      >
      <input id="tenantId" type="text" formControlName="tenantId" required />
    </div>
  </div>
  <button class="btn-success" type="submit" [disabled]="!addCustomerForm.valid">
    {{ "generic.save" | translate }}
  </button>
</form>
