import { Component, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSwitchComponent } from "../../shared/language-switch/language-switch.component";
import { AuthService } from '../auth.service';
import { CurrentUserStore } from './current-user.store';

@Component({
  selector: 'app-current-user',
  standalone: true,
  imports: [FontAwesomeModule, TranslateModule, LanguageSwitchComponent],
  templateUrl: './current-user.component.html',
  styleUrl: './current-user.component.scss'
})
export class CurrentUserComponent {
  currentUserStore = inject(CurrentUserStore);
  faUser = faUser

  constructor(private readonly authService: AuthService) {}

  logout() {
    this.authService.logout();
  }
}
