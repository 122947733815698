import { withDevtools, withStorageSync } from "@angular-architects/ngrx-toolkit";
import { patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";


export type ManageSubscriptionState = {
  subscriptionCanceled: boolean;
  periodEnd: Date | undefined;
  loading: boolean;
  showCancelDialog: boolean;
}

const initialState: ManageSubscriptionState = {
  subscriptionCanceled: false,
  periodEnd: undefined,
  loading: false,
  showCancelDialog: false,
}

export const ManageSubscriptionStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ }) => ({})),
  withDevtools('ManageSubscriptionStore'),
  withStorageSync('ManageSubscriptionStore'),
  withMethods((store) => ({
    cancelSubscription: (date: Date) => patchState(store, { subscriptionCanceled: true, periodEnd: date }),
    setLoading: () => patchState(store, { loading: true }),
    stopLoading: () => patchState(store, { loading: false }),
    toggleCancelDialog: () => patchState(store, { showCancelDialog: !store.showCancelDialog() }),
  })
));
