export enum Priority {
  None = 'None',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export class PriorityHelper {
  static getPriorityFromString(priority: string): Priority {
    switch (priority) {
      case 'A':
        return Priority.A;
      case 'B':
        return Priority.B;
      case 'C':
        return Priority.C;
      case 'D':
        return Priority.D;
      default:
        return Priority.None;
    }
  }
}
