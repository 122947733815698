import { Component, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCheckCircle,
  faCubes,
  faFileCircleCheck,
  faSackDollar,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { RegisterStore } from '../register.store';

@Component({
  selector: 'app-progress-display',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './progress-display.component.html',
  styleUrl: './progress-display.component.scss',
})
export class ProgressDisplayComponent {
  faCubes = faCubes;
  faUserTie = faUserTie;
  faEuroSign = faSackDollar;
  faFileCircleCheck = faFileCircleCheck;
  faCheckCircle = faCheckCircle;

  registerStore = inject(RegisterStore);
}
