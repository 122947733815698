<app-dialog [showDialog]="showDialog()">
  @if (!showCreateWorkgroupAlert()) {
  <app-form
    [titleKey]="'projects.add.title'"
    [fields]="fields()"
    (submit)="addProject($event)"
    (cancel)="cancel()"
  />
  } @else {
  <h1>{{ "projects.add.create-workgroup-first" | translate }}</h1>
  @if (currentUserStore.isElevatedUser()) {
  <p>
    {{ "projects.add.create-workgroup-first-description" | translate }}
  </p>
  <div class="btn-group">
    <a class="btn-secondary" routerLink="/user/workgroups">
      {{ "projects.add.create-workgroup" | translate }}
    </a>
    <button class="btn-dark" (click)="cancel()">
      {{ "generic.cancel" | translate }}
    </button>
  </div>
  } @else {
  <p>
    {{ "projects.add.contact-your-admin" | translate }}
  </p>
  <button class="btn btn-secondary" (click)="cancel()">
    {{ "generic.ok" | translate }}
  </button>
  } }
</app-dialog>
