@if (diagram() && riskData()) { @if (!diagram()?.archived) {
<div class="btn-group">
  <button id="add-risk-btn" class="btn-dark" (click)="addRisk()">
    <fa-icon [icon]="faTriangleExclamation"></fa-icon>
    {{ "diagrams.risk-matrix.add-risk" | translate }}
  </button>
</div>
}
<div class="diagram-canvas">
  <div class="diagram-canvas__legend__impact">
    <span class="diagram-canvas__legend__impact__catastrophic">
      {{ "diagrams.risk-matrix.legend.catastrophic" | translate }}
    </span>
    <span class="diagram-canvas__legend__impact__major">
      {{ "diagrams.risk-matrix.legend.major" | translate }}
    </span>
    <span class="diagram-canvas__legend__impact__moderate">
      {{ "diagrams.risk-matrix.legend.moderate" | translate }}
    </span>
    <span class="diagram-canvas__legend__impact__minor">
      {{ "diagrams.risk-matrix.legend.minor" | translate }}
    </span>
    <span class="diagram-canvas__legend__impact__insignificant">
      {{ "diagrams.risk-matrix.legend.insignificant" | translate }}
    </span>
  </div>
  <div class="risk-matrix">
    @for (i of [].constructor(25); track $index) {
    <div class="grid-item"></div>
    } @for (risk of riskData()!.risks; track risk.id) {
    <div
      class="risk-card"
      cdkDrag
      cdkDragBoundary=".risk-matrix"
      [cdkDragDisabled]="diagram()?.archived"
      (cdkDragEnded)="onDragEnd($event)"
      [cdkDragData]="risk"
      [style.bottom.%]="risk.position.y"
      [style.left.%]="risk.position.x"
    >
      <div class="risk-card__header">
        @if (risk.name.length > 25) {
        <app-tooltip [location]="'right'">
          <h2 tooltip-trigger>{{ risk.name | slice : 0 : 25 }}</h2>
          <p tooltip-content>{{ risk.name }}</p>
        </app-tooltip>
        } @else {
        <h2>{{ risk.name }}</h2>
        } @if (!diagram()?.archived) {
        <fa-icon [icon]="faTrash" (click)="onDeleteRisk(risk.id)"></fa-icon>
        }
      </div>
      <div class="risk-card__content">
        <textarea class="readonly-textarea" readonly>{{
          risk.description
        }}</textarea>
      </div>
    </div>
    }
    <span class="risk-matrix__legend__probability-title">
      {{ "diagrams.risk-matrix.legend.probability-title" | translate }}
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </span>
  </div>
  <div class="risk-matrix__legend__possibility">
    <span class="risk-matrix__legend__possibility__impossible">
      {{ "diagrams.risk-matrix.legend.impossible" | translate }}
    </span>
    <span class="risk-matrix__legend__possibility__unlikely">
      {{ "diagrams.risk-matrix.legend.unlikely" | translate }}
    </span>
    <span class="risk-matrix__legend__possibility__possible">
      {{ "diagrams.risk-matrix.legend.possible" | translate }}
    </span>
    <span class="risk-matrix__legend__possibility__likely">
      {{ "diagrams.risk-matrix.legend.likely" | translate }}
    </span>
    <span class="risk-matrix__legend__possibility__certain">
      {{ "diagrams.risk-matrix.legend.certain" | translate }}
    </span>
  </div>
  <span class="risk-matrix__legend__impact-title">
    {{ "diagrams.risk-matrix.legend.impact-title" | translate }}
    <fa-icon [icon]="faArrowRight"></fa-icon>
  </span>
</div>
<app-dialog [showDialog]="showAddRiskDialog()">
  <app-form
    [titleKey]="'diagrams.risk-matrix.dialog.title'"
    [fields]="fields"
    (submit)="onAddRisk($event)"
    (cancel)="onCancelAddRisk()"
  />
</app-dialog>
}
<app-confirmation-dialog
  [show]="showDeleteRiskDialog()"
  [messageKey]="'diagrams.risk-matrix.delete-risk'"
  (confirm)="onDeleteRiskConfirmed()"
  (cancel)="onDeletedRiskCancel()"
/>
