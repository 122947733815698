<h1>{{ "admin.customers.detail.title" | translate }}</h1>
@if (loading) {
<app-loading-spinner />
} @else {
<div class="btn-group">
  <button class="btn-warning" (click)="editCustomer()">
    {{ "admin.customers.detail.editBtn" | translate }}
  </button>
</div>
<h2>{{ customer.name }}  <span>({{ 'sku.' + customer.sku | translate }})</span></h2>
<app-data-group [labelKey]="'admin.customers.detail.general'">
  <div class="data">
    <div id="column0">
      <app-data-point
        labelKey="admin.customers.detail.label.id"
        [value]="customer.id"
        type="text"
      ></app-data-point>
      <app-data-point
        labelKey="admin.customers.detail.label.vatId"
        [value]="customer.vatId"
        type="text"
      ></app-data-point>
      <app-data-point
        labelKey="admin.customers.detail.label.tenantId"
        [value]="customer.tenantId"
        type="text"
      ></app-data-point>
    </div>
    <div id="column1">
      <app-data-point
        [labelKey]="'admin.customers.detail.label.active'"
        [value]="customer.active"
        type="boolean"
      >
      </app-data-point>
      <app-data-point
        [labelKey]="'admin.customers.detail.label.created'"
        [value]="customer.createdAt"
        type="datetime"
      >
      </app-data-point>
      <app-data-point
        [labelKey]="'admin.customers.detail.label.updated'"
        [value]="customer.updatedAt"
        type="datetime"
      >
      </app-data-point>
    </div>
  </div>
</app-data-group>
<app-users [customerId]="customerId"></app-users>
}
