import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { DiagramRepository } from '../diagram.repository';
import { KanbanComponent } from '../kanban/kanban.component';
import { LeanCanvasComponent } from '../lean-canvas/lean-canvas.component';
import { DiagramModel } from '../models/diagram.model';
import { PriorityComponent } from '../priority/priority.component';
import { RiskMatrixComponent } from '../risk-matrix/risk-matrix.component';

@Component({
  selector: 'app-diagram-viewer',
  standalone: true,
  imports: [PriorityComponent, LeanCanvasComponent, KanbanComponent, RiskMatrixComponent],
  templateUrl: './diagram-viewer.component.html',
  styleUrl: './diagram-viewer.component.scss',
})
export class DiagramViewerComponent implements OnInit {
  destroy$ = new Subject<void>();
  diagramId = signal<string>('');
  diagram = signal<DiagramModel | undefined>(undefined);
  diagramType = signal<string | undefined>(undefined);

  constructor(
    private readonly diagramRepository: DiagramRepository,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.diagramId.set(this.activatedRoute.snapshot.params['id']);
    this.loadDiagram();
  }

  loadDiagram(): void {
    this.diagramRepository
    .get(this.diagramId())
    .pipe(takeUntil(this.destroy$))
    .subscribe((diagram) => {
      const diagramData = JSON.parse(diagram.diagramJson);
      this.diagramType.set(diagramData.type);
      this.diagram.set(diagram);
    });
  }
}
