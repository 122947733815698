<h1>{{ "organization-users.title" | translate }}</h1>
@if (customerUsersStore.loading()) {
<app-loading-spinner />
} @else {
<div class="btn-group">
  <button
    [disabled]="customerUsersStore.maxUsersReached()"
    (click)="onAddUser()"
    class="btn btn-success"
  >
    <fa-icon [icon]="faPlus"></fa-icon>
    {{ "organization-users.add-user" | translate }}
  </button>
</div>
<app-user-information
  [activeUsers]="customerUsersStore.activeUserCount()"
  [maxUsers]="customerUsersStore.maxUsers()"
/>

<app-data-table [columns]="columns" [data]="customerUsersStore.users()" />
}
<app-invite-user
  [showDialog]="showAddUserDialog()"
  (submitted)="onSubmit()"
  (canceled)="onCancel()"
/>
<app-change-role-dialog
  [showDialog]="showChangeRoleDialog()"
  [user]="currentlySelectedUser()"
  (submitted)="onSubmitRoleChange()"
  (canceled)="onCancelRoleChange()"
/>
<app-confirmation-dialog
  [message]="deleteUserString()"
  [show]="showDeleteDialog()"
  (confirm)="onDeleteUserConfirm()"
  (cancel)="onDeleteUserCancel()"
/>
