<div
  class="tooltip-container"
  (mouseenter)="showTooltip()"
  (mouseleave)="hideTooltip()"
  (touch)="showTooltip()"
>
  <ng-content select="[tooltip-trigger]"></ng-content>
  @if (tooltipVisible()) {
  <div
    class="tooltip-content"
    (mouseenter)="showTooltip()"
    (touch)="hideTooltip()"
    (mouseleave)="hideTooltip()"
    [class.left]="location() === 'left'"
    [class.right]="location() === 'right'"
    [class.top]="location() === 'top'"
    [class.bottom]="location() === 'bottom'"
  >
    <ng-content select="[tooltip-content]"></ng-content>
  </div>
  }
</div>
