import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AddCustomerModel } from "../../admin-customers/add-customer/add-customer.model";
import { Sku } from "../../models/enums/sku.enum";
import { HttpService } from "../../shared/http.service";

const apiUrl = environment.apiConfig.url;

@Injectable({ providedIn: 'root' })
export class RegisterRepository {
  constructor(private readonly httpService: HttpService) {}

  registerCustomer(customer: AddCustomerModel): Observable<string> {
    return this.httpService.post(apiUrl + "/register", customer);
  }

  registerTestVersionForCustomer(customer: AddCustomerModel) {
    return this.httpService.post(apiUrl + "/register/test-version", customer);
  }

  getCheckoutSession(customerId: string, sku: Sku, givenUserCount: number): Observable<{ sessionUrl: string }> {
    return this.httpService.get<{ sessionUrl: string }>(apiUrl + `/register/checkout-session/${customerId}/${sku}/${givenUserCount}`);
  }

  confirmPayment(sessionId: string, customerId: string) {
    return this.httpService.get(apiUrl + `/register/confirm-payment/${sessionId}/${customerId}`);
  }
}
