import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { Sku } from '../../models/enums/sku.enum';
import { CustomerRepository } from '../customer.repository';

@Component({
  selector: 'app-add-customer',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, TranslateModule],
  templateUrl: './add-customer.component.html',
  styleUrl: './add-customer.component.scss'
})
export class AddCustomerComponent implements OnDestroy {
  destroy$ = new Subject<void>();
  addCustomerForm = new FormGroup({
  name: new FormControl(''),
  adminEmail: new FormControl(''),
  sku: new FormControl(Sku.Basic),
  givenUserCount: new FormControl(1),
  tenantId: new FormControl(''),
})

/**
 *
 */
constructor(
  private readonly customerService: CustomerRepository,
  private readonly router: Router) {


}
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


onSubmit() {
  this.customerService.addCustomer({
    name: this.addCustomerForm.value.name!,
    adminEmail: this.addCustomerForm.value.adminEmail!,
    sku: this.addCustomerForm.value.sku!,
    givenUserCount: this.addCustomerForm.value.givenUserCount!,
    tenantId: this.addCustomerForm.value.tenantId!
  })
  .pipe(takeUntil(this.destroy$))
  .subscribe(() => {
    this.router.navigate(['/admin/customers']);
  });
}
}


